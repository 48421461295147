import { configureStore } from "@reduxjs/toolkit"

import { mainSlice } from "app/homepage/slice"

import { bookingsApi } from "pages/bookings/slice"
import { newBookingApi } from "../pages/bookings/newBookingApi"
import { mastersApi, photoApi } from "pages/masters/slice"
import { categoriesApi } from "pages/categories/slice"
import bookingsCalendarReducer from "pages/bookingsCalendar/slice"
import { authorizationApi } from "pages/auth/slice"
import { servicesApi } from "pages/services/slice"
import { settingsApi } from "../pages/settings/settingsApi"
import { userProfileApi } from "../pages/profile/userProfileApi"
import { newFeedbackApi } from "../pages/feedback/feedbackApi"
import { templatesApi } from "../pages/laboratory/templates/templatesApi"
import {customersApi} from "../pages/customers/customersApi"
import settingsSliceReducer from "../pages/settings/settingsSlice.ts"
import spheresSliceReducer from "../pages/settings/spheresSlice.ts"
import citiesSliceReducer from "../pages/settings/citiesSlice.ts"
import bookingSliceReducer from "../pages/bookings/bookingSlice.ts"
import categoriesSliceReducer from "../pages/categories/categoriesSlice.ts"
import customersSliceReducer from "../pages/customers/customersSlice.ts"
import statusesSliceReducer from "../pages/bookings/statusesSlice.ts"
import profileReducer from "../pages/profile/userProfileApi"
import changePassSliceReducer from "../features/headerMenu/changePassSlice.ts"
import StepSliceReducer from './homepage/stepSlice'
import EditFormSliceReducer from '../pages/laboratory/analyzes/formPage/editFormSlice'
import { analyzesApi } from "../pages/laboratory/analyzes/analyzesApi"
// import { worktimeApi } from 'pages/workTimeCalendar/slice'

const rootReducer = {
  settingsSliceReducer,
  spheresSliceReducer,
  citiesSliceReducer,
  bookingSliceReducer,
  categoriesSliceReducer,
  customersSliceReducer,
  statusesSliceReducer,
  changePassSliceReducer,
  bookingsCalendarReducer,
  profileReducer,
  StepSliceReducer,
  EditFormSliceReducer,
  [authorizationApi.reducerPath]: authorizationApi.reducer,
  [categoriesApi.reducerPath]: categoriesApi.reducer,
  [templatesApi.reducerPath]: templatesApi.reducer,

  [bookingsApi.reducerPath]: bookingsApi.reducer,
  [servicesApi.reducerPath]: servicesApi.reducer,
  [mastersApi.reducerPath]: mastersApi.reducer,
  [photoApi.reducerPath]: photoApi.reducer,
  [settingsApi.reducerPath]: settingsApi.reducer,
  [userProfileApi.reducerPath]: userProfileApi.reducer,
  [newFeedbackApi.reducerPath]: newFeedbackApi.reducer,
  [customersApi.reducerPath]: customersApi.reducer,
  [newBookingApi.reducerPath]: newBookingApi.reducer,
  [analyzesApi.reducerPath]: analyzesApi.reducer,
  // [worktimeApi.reducerPath]: worktimeApi.reducer,

  main: mainSlice.reducer,
  // bookingsCalendar: bookingsCalendarSlice.reducer,
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      authorizationApi.middleware,
      servicesApi.middleware,
      categoriesApi.middleware,
      bookingsApi.middleware,
      mastersApi.middleware,
      photoApi.middleware,
      settingsApi.middleware,
      userProfileApi.middleware,
      newFeedbackApi.middleware,
      templatesApi.middleware,
      customersApi.middleware,
      newBookingApi.middleware,
      analyzesApi.middleware,
      // worktimeApi.middleware
    ),
})

