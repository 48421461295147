import { useEffect, useState } from "react"

import ReactInputMask from "react-input-mask"
import { useFormik } from "formik"

import { ErrorMsg } from "shared/ui/input/ErrorMsg"
import { GoBackBtn } from "./GoBackBtn"
import { schemes } from "./validations"

export const CodeConfirmation = ({ backSlide, submit, errorMsg, resent, hidden1 }) => {
  const [codeTimer, setCodeTimer] = useState(0)

  function resentCode() {
    resent()
    setCodeTimer(30)
  }

  useEffect(() => {
    if (codeTimer > 0) {
      setTimeout(() => {
        setCodeTimer(codeTimer - 1)
      }, 1000)
    }
  }, [codeTimer])

  const [clickTimer, setClickTimer] = useState(0)
  useEffect(() => {
    if (clickTimer > 0) {
      setTimeout(() => {
        setClickTimer(clickTimer - 1)
      }, 1000)
    }
  }, [clickTimer])

  const { values, handleChange, errors, touched, handleSubmit } = useFormik({
    initialValues: {
      code: "",
    },
    validationSchema: schemes.getSchemeOf(["code"]),
    onSubmit: ({ code }) => {
      submit(code)
      setClickTimer(5)
    },
  })

  return (
    <div className={hidden1 ? `hidden` : ''}>
      <div className="max-w-[344px] w-full relative ">
        <div className="text-center max-w-[276px] mx-auto mb-7">
          <h1 className="text-[27px] font-medium mb-6">
            Вам отправлен код подтверждения
          </h1>
        </div>

        <div className="w-full text-left mb-9">
          <label className="label-base flex flex-col relative" htmlFor="code">
            Код подтверждения
            <ReactInputMask
              name={"code"}
              mask={"9999"}
              maskPlaceholder=""
              className="inputOutlined mt-2 tracking-[5px]"
              type="text"
              value={values.code}
              onChange={handleChange}
              placeholder="----"
              onKeyDown={(e) =>
                e.code === "Enter" &&
                values.code.length === 5 &&
                handleSubmit()
              }
            />
            {errors.code && touched.code && <ErrorMsg title={errors.code} />}
          </label>
        </div>

        <div className="h-[20px] mb-3">
          {codeTimer === 0 ? (
            <button
              onClick={resentCode}
              className="text-sm block w-fit mx-auto h-fit"
            >
              <span
                onClick={(e) => e.stopPropagation()}
                className="cursor-default"
              >
                Не пришёл код?{" "}
              </span>
              <span className="text-orange hover:underline font-medium">
                Отправить повторно
              </span>
            </button>
          ) : (
            <p className="text-center text-orange mb-3 cursor-default">
              {codeTimer}
            </p>
          )}
        </div>

        <button
          type="button"
          onClick={handleSubmit}
          disabled={clickTimer > 0}
          className="btnFilled w-full mb-4"
        >
          Далее
        </button>
        <div className="text-center mt-1">
          <GoBackBtn handleClick={backSlide} />
        </div>
        {errorMsg && (
          <ErrorMsg className="-bottom-10 text-center" title={errorMsg} />
        )}
      </div>
    </div>
  )
}
