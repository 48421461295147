import { useFormik } from "formik"
import { useEffect, useState } from "react"

import { ErrorMsg } from "shared/ui/input/ErrorMsg"
import { GoBackBtn } from "../../../widgets/auth/GoBackBtn"
import { PhoneInput } from "../../../shared/ui/input/PhoneInput"
import { schemes } from "../../../widgets/auth/validations"

export const EnterPhone = ({ backSlide, submit, errorMsg, phoneError }) => {
  const [timer, setTimer] = useState(0)
  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1)
      }, 1000)
    }
  }, [timer])

  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema: schemes.getSchemeOf(["phone"]),
    onSubmit: ({ phone }) => {
      submit(phone)
      setTimer(3)
    },
  })

  return (
    <div
      className="max-w-[344px] w-full"
      onKeyDown={(e) =>
        e.code === "Tab" && e.target.nodeName === "BUTTON" && e.preventDefault()
      }
    >
      <div className="text-center w-full mx-auto mb-6">
        <h1 className="text-[27px] font-medium mb-6 text-center">
          <p className="block">Введите ваш</p>
          <span className="block">номер телефона</span>
        </h1>
      </div>
      <div className="text-left mb-8">
        <label className="label-base flex flex-col relative">
          <span className="mb-1">Номер телефона</span>
          <PhoneInput
            name="phone"
            onKeyDown={(e) =>
              e.code === "Enter" &&
              Number(values.tel[values.tel.length - 1]) &&
              handleSubmit()
            }
            value={values.phone}
            onChange={handleChange}
          />
          {phoneError && <p className='text-red ml-2 mt-1'> Такого пользователя не существует</p>}
        </label>
      </div>
      <div>
        <button
          type="button"
          disabled={timer > 0}
          onClick={handleSubmit}
          className="btnFilled w-full"
        >
          Далее
        </button>
        <div className="text-center mt-4">
          <GoBackBtn handleClick={backSlide} />
        </div>
        {errorMsg && (
          <ErrorMsg className="-bottom-10 text-center" title={errorMsg} />
        )}
      </div>
    </div>
  )
}
