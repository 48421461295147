import { Modal } from "@mui/material"
import html2canvas from "html2canvas"
import { jsPDF } from "jspdf"
import React, { useEffect, useState } from "react"
import { useSendPdfToSmsMutation, useUpdateSmsStatusMutation } from "../analyzesApi"
import { toast } from "react-hot-toast"
import { useGetCitiesQuery } from "../../../settings/settingsApi"

const user = JSON.parse(localStorage.getItem('user'))
function getCurrentDateTime() {
  const now = new Date()

  const day = String(now.getDate()).padStart(2, "0")
  const month = String(now.getMonth() + 1).padStart(2, "0") // Месяцы начинаются с 0
  const year = now.getFullYear()

  const hours = String(now.getHours()).padStart(2, "0")
  const minutes = String(now.getMinutes()).padStart(2, "0")

  return `${day}.${month}.${year} ${hours}:${minutes}`
}
const formatRightDate = (date) => {

  const newDate = new Date(date)
  const year = newDate.getFullYear()
  const month = (newDate.getMonth() + 1).toString().padStart(2, "0") // +1, так как месяцы начинаются с 0
  const day = newDate.getDate().toString().padStart(2, "0")

  return `${day}-${month}-${year}`
}



export const AllTestsModal = ({open, setOpen, data}) => {
  const [cityName, setCityName] = useState('')
  const {data: citiesData} = useGetCitiesQuery()
  const [sendSms, {isLoading:smsLoading}] = useSendPdfToSmsMutation()
  const [reportSms, {isLoading:reportSmsLoading}] = useUpdateSmsStatusMutation()
  console.log(data)
  useEffect(() => {
    const cityName = citiesData?.find(item => item.id === user?.salon?.city_id)
    setCityName(cityName?.name)
  }, [user, citiesData])
  const openPdfInNewTab = async () => {
    const pdf = await generatePdf()
    const pdfBlob = pdf.output('blob', { compress: true });

    const pdfUrl = URL.createObjectURL(pdfBlob);

    window.open(pdfUrl, '_blank');
  };

  const sendPdfToSms = async () => {
    const pdf = await generatePdf()
    const pdfBlob = pdf.output('blob', { compress: true });
    try {
      const formData = new FormData()
      formData.append('file', pdfBlob, 'generated.pdf')
      formData.append('phone', data?.report?.phone)
      await sendSms(formData).unwrap()
    } catch (err) {
      if(err.data === 'OK') {
        await reportSms({id: data?.report?.id})
        toast.success('Смс успешно отправлено клиенту', {position: 'bottom-right'})
      } else {
        toast.error('Ошибка при отправке смс', {position: 'bottom-right'})
      }
    }
  }
  const generatePdf = async () => {
    const content = document.getElementById("tests_pdf");
    const canvas = await html2canvas(content, {
      scale: 1, // Увеличить качество изображения
      useCORS: true, // Если изображения загружаются с других доменов
    });
    const imgData = canvas.toDataURL("image/png");
    const pdfWidth = canvas.width * 0.5; // Уменьшение размера изображения
    const pdfHeight = canvas.height * 0.5;

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: [pdfWidth, pdfHeight]
    });
    pdf.addImage(imgData, "PNG", 0, 0, canvas.width * 0.5, canvas.height * 0.5, undefined, 'FAST');
    return pdf;
  }

    return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <div className="w-[1000px] h-full bg-white mx-auto mt-4 pb-10 overflow-y-auto text-black">
        <div id={"tests_pdf"} className="min-h-[900px] text-black w-full pt-12 px-12 pb-5 bg-white overflow-y-auto">
          <div className="text-start text-black font-medium text-[20px]">
            <p>"Дента"</p>
            <p>{`г. ${cityName}, ул. ${user?.salon?.street} ${user?.salon?.building}`}</p>
            <p>{`тел: +992 ${user?.salon?.phone}`}</p>
            <p>{getCurrentDateTime()}</p>
          </div>
          <div className="flex items-center justify-start w-full mt-6 gap-[200px]">
            <div className="flex flex-col items-start justify-center gap-1">
              <p className="text-[#737C86] text-[14px] font-medium">ID анализа</p>
              <p>{data?.report?.id}</p>
            </div>
            <div className="flex flex-col items-start justify-center gap-1">
              <p className="text-[#737C86] text-[14px] font-medium">ФИО</p>
              <p>{data?.report?.surname} {data?.report?.name} {data?.report?.patronymic || ''}</p>
            </div>
            <div className="flex flex-col items-start justify-center gap-1">
              <p className="text-[#737C86] text-[14px] font-medium">Дата рождения</p>
              <p>{formatRightDate(data?.report.birthday)}</p>
            </div>
          </div>
          {data?.templates.map(template => <div>
            <h1 className="text-[32px] font-medium text-center mt-8">{template?.name}</h1>
            <div
              className="flex items-center justify-start gap-10 w-[800px] mt-10 px-7 border rounded-t-[8px] border-lightGray py-3">
              <p className="w-full whitespace-nowrap font-medium text-[#A4AFBD] text-[14px]">Название показателя</p>
              <p className="w-full whitespace-nowrap font-medium text-[#A4AFBD] text-[14px]">Результат</p>
              <p className="w-full whitespace-nowrap font-medium text-[#A4AFBD] text-[14px]">Норма</p>
              <p className="w-full whitespace-nowrap font-medium text-[#A4AFBD] text-[14px]">Eд.измер</p>
            </div>
            <div
              className="w-[800px] flex flex-col gap-2 border-x border-b border-lightGray  overflow-y-auto">
              {template.items.map(item =>
                <div key={item.id} className="w-full flex items-center border-b border-lightGray justify-between py-4">

                  <p className={`text-[16px] px-7 w-[26%]`}>{item.name}</p>
                  <p className={`text-[16px] px-7 w-[25%]`}>{item.result}</p>
                  <p className={`text-[16px] px-7 w-[25%]`}>{item.norm}</p>
                  <p className={`text-[16px] px-7 w-[25%]`}>{item.unit}</p>
                  <p></p>

                </div>)}
            </div>
          </div>)}

        </div>
        <div className='flex items-center justify-center gap-4'>
          <button disabled={smsLoading || reportSmsLoading} className={smsLoading || reportSmsLoading ? "opacity-50 btnFilled" : "btnFilled"} type={'button'} onClick={openPdfInNewTab}>Распечатать</button>
          <button
            disabled={smsLoading || reportSmsLoading}
            type={'button'}
            onClick={sendPdfToSms}
            className={smsLoading || reportSmsLoading ? "opacity-50 btnOutlined px-4" : "btnOutlined px-4"}>
            Отправить смс
          </button>
        </div>
      </div>
    </Modal>
  )
}

