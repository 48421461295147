import { types } from "mobx-state-tree";
import { BookingPageStore } from "./BookingsPageStore";
import { NotificationsPageStore } from "./NotificationsPageStore";
import { CustomersPageStore } from "./CustomersPageStore";
import { CategoriesPageStore } from "./CategoriesPageStore";

export const PagesStore = types.model('PagesStore', {
    bookings: BookingPageStore,
    notifications: NotificationsPageStore,
    customers: CustomersPageStore,
    categories: CategoriesPageStore,
})
