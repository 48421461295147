import { createApi } from "@reduxjs/toolkit/query/react"

import { baseQueryWithReauth, getApiConfig } from "shared/api"
import { currentUrl } from "../../url"

const { BASE_URL, prepareHeaders } = getApiConfig()
const GET_CATEGORIES_PATH = "master/tree"
const GET_SEARCH_PATH = "master/search/up-categories"
const MUTATE_CATEGORIES_PATH = "master/up-categories"
const SUB_CATEGORIES_PATH = "master/sub-categories"
const SERVICES_PATH = "master/services"

const BUSINESS_CATEGORIES_PATH = "master/categories"

// const category_id = 2 // FIX_ME

function transformGetCategoriesResponse({ data }) {
  return data.map(({ id, name, sub_category_services }) => ({
    id,
    name,
    subCategories: sub_category_services?.map(({ id, name, services }) => ({
      id,
      name,
      services: services?.map(({ id, name, price, duration }) => ({
        id,
        name,
        price,
        duration,
      })),
    })),
  }))
}

export const categoriesApi = createApi({
  reducerPath: "categoriesApi",
  tagTypes: ["Categories"],
  baseQuery: baseQueryWithReauth({baseUrl: BASE_URL, prepareHeaders}),
  endpoints: (builder) => ({
    // getAll
    getCategories: builder.query({
      query: () =>
        `/${GET_CATEGORIES_PATH}`,
      // providesTags: ({ data }) => {
      //   if (data) {
      //     return [
      //       ...data.map(({ id }) => ({ type: "Categories", id })),
      //       { type: "Categories", id: "LIST" },
      //     ]
      //   }
      //   return [{ type: "Categories", id: "LIST" }]
      // },
      // transformResponse: transformGetCategoriesResponse,
    }),
    getSearchCategories: builder.query({
      query: (params) => `/${GET_SEARCH_PATH}?keyword=${params.search}`
    }),
    // не получается делать post, нужен category_id (id сферы деятельности)
    // post
    postCategory: builder.mutation({
      query: body => ({
        url: `/${MUTATE_CATEGORIES_PATH}`,
        method: "POST",
        body,
      }),
      // invalidatesTags: [{ type: "Categories", id: "LIST" }],
    }),
    // put
    updateCategory: builder.mutation({
      query: ({ name, id }) => ({
        url: `/${MUTATE_CATEGORIES_PATH}/${id}`,
        method: "PUT",
        body: { name },
      }),
      // invalidatesTags: [{ type: "Categories", id: "LIST" }],
    }),
    // delete
    removeCategory: builder.mutation({
      query: (id) => ({
        url: `/${MUTATE_CATEGORIES_PATH}/${id}`,
        method: "DELETE",
      }),
      // invalidatesTags: [{ type: "Categories", id: "LIST" }],
    }),

    searchService: builder.query({
      query: (params) => `${currentUrl}/master/search/services?keyword=${params.search}`
    }),

    // subcategories api

    // post
    postSubCategories: builder.mutation({
      query: body => ({
        url: `/${SUB_CATEGORIES_PATH}`,
        method: "POST",
        body
      }),
      invalidatesTags: [{ type: "Categories", id: "LIST" }],
    }),
    // put
    updateSubCategory: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `/${SUB_CATEGORIES_PATH}/${id}`,
        method: "PUT",
        body
      }),
      invalidatesTags: [{ type: "Categories", id: "LIST" }],
    }),
    // delete
    removeSubCategory: builder.mutation({
      query: (id) => ({
        url: `/${SUB_CATEGORIES_PATH}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Categories", id: "LIST" }],
    }),
    addService: builder.mutation({
      query: body => ({
        url: `/${SERVICES_PATH}`,
        method: 'POST',
        body
      }),
      invalidatesTags: [{ type: "Categories", id: "LIST" }],
    }),
    editService: builder.mutation({
      query: ({id, ...body}) => ({
        url: `/${SERVICES_PATH}/${id}`,
        method: 'PUT',
        body
      }),
      invalidatesTags: [{ type: "Categories", id: "LIST" }],
    }),
    removeService: builder.mutation({
      query: (id) => ({
        url: `/${SERVICES_PATH}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: "Categories", id: "LIST" }],
    }),
    getBusinessCategories: builder.query({
      query: () => `${BUSINESS_CATEGORIES_PATH}`
    }),

    // сфера услуг / сфера деятельности
    // getBusinessCategories: builder.query({
    //   query: () =>
    //     `/${BUSINESS_CATEGORIES_PATH}`,
    //   transformResponse: ({data}) => data
    // }),
  }),
})

export const {
  useGetCategoriesQuery,
  usePostCategoryMutation,
  useRemoveCategoryMutation,
  useUpdateCategoryMutation,
  useGetSearchCategoriesQuery,
  useSearchServiceQuery,

  usePostSubCategoriesMutation,
  useUpdateSubCategoryMutation,
  useRemoveSubCategoryMutation,

  useGetBusinessCategoriesQuery,

  useAddServiceMutation,
  useEditServiceMutation,
  useRemoveServiceMutation
} = categoriesApi
