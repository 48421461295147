import { DriveFileRenameOutlineOutlined } from "@mui/icons-material";

export function EditBtn({ onClick, type }) {
  return (
    <button type={type} onClick={onClick} data-title='Редактировать'>
      <DriveFileRenameOutlineOutlined
        sx={{
          color: "gray.main",
          "&:hover": { color: "primary.main" },
        }}
      />
    </button>
  );
}
