import { Done } from "@mui/icons-material"

export function StepsBar({ mounted, pageNum }) {
  return (
    <div
      className={`${
        mounted ? "translate-x-0" : "translate-x-[-100%]"
      } bg-blue h-screen px-7 py-24 w-[360px] transition-transform duration-500 sm:flex sm:flex-col hidden`}
    >
      <div>
        <img src='./assets/logo/logo.svg' alt='logo' />
      </div>
      <p className='text-white text-2xl font-medium mt-7'>Смена пароля</p>

      <div className='mt-11'>
        <div className='flex w-fit'>
          <div className='flex flex-col items-center'>
            <span className={`circle ${pageNum >= 1 && "bg-white"}`}>
              <span
                className={`dot ${
                  pageNum === 1 && "bg-orange"
                } flex items-center justify-center`}
              >
                {pageNum > 1 && (
                  <Done
                    fontSize='large'
                    color='secondary'
                    sx={{ fontWeight: "500", width: "18px" }}
                  />
                )}
              </span>
            </span>
            <p className='line'>
              <span
                className={`subline rounded-[50px] ${
                  pageNum > 1 && "sublineActive"
                }`}
              ></span>
            </p>
          </div>

          <div className='flex flex-col ml-3 mt-1'>
            {/* <p className="font-medium text-white">Смена пароля</p> */}
            <div className='text-white'>Номер телефона</div>
          </div>
        </div>

        <div className='flex w-fit'>
          <div className='flex flex-col items-center'>
            <span className={`circle ${pageNum >= 2 && "bg-white"}`}>
              <span
                className={`dot ${
                  pageNum < 2
                    ? "bg-white"
                    : pageNum === 2
                    ? "bg-orange"
                    : "bg-transparent"
                } flex items-center justify-center`}
              >
                {pageNum > 2 && (
                  <Done
                    fontSize='large'
                    color='secondary'
                    sx={{ fontWeight: "500", width: "18px" }}
                  />
                )}
              </span>
            </span>
            <p className='line'>
              <span
                className={`subline rounded-[50px] ${
                  pageNum > 2 && "sublineActive"
                }`}
              ></span>
            </p>
          </div>

          <div className='flex flex-col ml-3 mt-1 whitespace-nowrap overflow-hidden text-ellipsis'>
            <p className={`${pageNum >= 2 ? "text-white" : "text-gray"}`}>
              Код подтверждения
            </p>
          </div>
        </div>

        <div className='flex w-fit'>
          <div className='flex flex-col items-center'>
            <span
              className={`circle ${
                pageNum === 3 ? "bg-white" : "bg-transparent"
              }`}
            >
              <span
                className={`dot ${
                  pageNum < 3 ? "bg-white" : "bg-orange"
                } flex items-center justify-center`}
              ></span>
            </span>
          </div>

          <div className='flex flex-col ml-3 mt-1'>
            <p className={`${pageNum < 3 ? "text-gray" : "text-white"}`}>
              Введите новый пароль
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}