import { lazy, Suspense, useEffect, useState } from "react"
import { Navigate, Route, Routes } from "react-router-dom"

import { AuthSkeleton } from "widgets/fallbacks/AuthSkeleton"
import { TableSkeleton } from "widgets/fallbacks/TableSkeleton"
import { PageSkeleton } from "widgets/fallbacks/PageSkeleton"

import { HeaderMenu } from "entities/headerMenu/index"
import { Navbar } from "widgets/navbar/index"
import { MobileNav } from "widgets/navbar/MobileNav"
import { MobileSidebar } from "widgets/navbar/MobileSidebar"
import { getPaths } from "./routes"

// non-lazy pages
// import SalonCalendar from "pages/bookingsCalendar"

import { AuthPage } from "pages/auth"
import { Reviews } from "pages/reviews"
import UserProfile from "pages/profile/UserProfile"

import SignIn from "pages/auth/signIn/index"
import ChangePass from "pages/auth/changePass/index"
import { useStore } from "hooks"
import { observer } from "mobx-react-lite"
import { Feedback } from "pages/feedback"
// import Bookings from "../../pages/bookings"
import { Templates } from "../../pages/laboratory/templates/Templates"
import { useDispatch } from "react-redux"
import { setStep } from "./stepSlice"
import { Analyzes } from "../../pages/laboratory/analyzes/Analyzes"
import { FormPage } from "../../pages/laboratory/analyzes/formPage/FormPage"

const publicLazyRoutes = [
  { path: "/signIn", Component: SignIn, Fallback: AuthSkeleton },
  { path: "/changePass", Component: ChangePass, Fallback: AuthSkeleton },
]

const AdminBookingList = lazy(() => import("pages/bookings"))
const AdminBookingCalendarList = lazy(() => import("pages/bookingsCalendar/index"))
const Masters = lazy(() => import("pages/masters/index"))
const Settings = lazy(() => import("pages/settings/index"))
// const AdminProfile = lazy(() => import("pages/profile/AdminProfile"))
const adminLazyPathways = [
  { path: "/bookings", Component: AdminBookingList, Fallback: TableSkeleton },
  { path: "/bookings-calendar", Component: AdminBookingCalendarList, Fallback: PageSkeleton },
  { path: "/masters", Component: Masters, Fallback: TableSkeleton },
  { path: "/settings", Component: Settings, Fallback: PageSkeleton },
  { path: "/laboratory", Component: Masters, Fallback: TableSkeleton },
  { path: "/feedback", Component: Feedback, Fallback: PageSkeleton},
  { path: "/profile", Component: UserProfile, Fallback: PageSkeleton },
  // {path: "/profile", Component: AdminProfile, Fallback: PageSkeleton},
]

const Customers = lazy(() => import("pages/customers/index"))
const Categories = lazy(() => import("pages/categories/index"))
const Services = lazy(() => import("pages/services/index"))
const noPermissionPathways = [
  { path: "/customers", Component: Customers, Fallback: TableSkeleton },
  { path: "/laboratory", Component: Masters, Fallback: TableSkeleton },
  { path: "/categories", Component: Categories, Fallback: TableSkeleton },
  { path: "/services", Component: Services, Fallback: TableSkeleton },
  { path: "/reviews", Component: Reviews, Fallback: PageSkeleton },
]
const pathList = getPaths(true)

const user = JSON.parse(localStorage.getItem('user'))
const localStep = user?.step

export const Homepage = observer(() => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setStep(localStep))
  }, [localStep])

  const { auth } = useStore()
  const [hiddenNavbar, setHiddenNavbar] = useState(false)
  const [hiddenMobileNavbar, setHiddenMobileNavbar] = useState(true)

  const isAdmin = true
  const isAuthorized = auth.accessToken.length > 0

  function toggleMobileNavbar() {
    setHiddenMobileNavbar(!hiddenMobileNavbar)
  }

  return (
    <>
      {isAuthorized ? (
        <div className={`flex min-h-[100vh] relative`}>
          <Navbar
            hidden={hiddenNavbar}
            setHidden={setHiddenNavbar}
            pathList={pathList}
          />
          <MobileNav pathList={pathList} />
          <MobileSidebar
            isHidden={hiddenMobileNavbar}
            pathList={pathList}
            toggleNavbar={toggleMobileNavbar}
          />
          <div className=" pb-[60px] sm:pb-[25px] w-full flex flex-col sm:px-[40px] pt-[68px] sm:pt-6 h-screen overflow-auto">
            <HeaderMenu toggleNavbar={toggleMobileNavbar} />

            <Routes>
              {/* pathways available without permission */}
              {noPermissionPathways.map(({ path, Component, Fallback }) => (
                <Route
                  key={path}
                  path={path}
                  element={
                    <Suspense fallback={<Fallback />}>
                      <Component />
                    </Suspense>
                  }
                />
              ))}

              {isAdmin ? (
                <>
                  {/*<Route path="/bookings" element={<Bookings />} />*/}
                  {adminLazyPathways.map(({ path, Component, Fallback }) => (
                    <Route
                      key={path}
                      path={path}
                      element={
                        <Suspense fallback={<Fallback />}>
                          <Component />
                        </Suspense>
                      }
                    />
                  ))}
                  <Route path="*" element={<Navigate to="/bookings" />} />
                  <Route path="/templates" element={<Templates/>} />
                  <Route path="/analyzes" element={<Analyzes/>} />
                  <Route path="/analyzes/new" element={<FormPage/>} />
                  <Route path="/analyzes/:id" element={<FormPage/>} />
                </>
              ) : (
                <>
                  {/* <Route path='/bookings-calendar' element={<PersonalCalendar />} />
                  <Route path='/schedules' element={<PersonalWorkTimeCalendar />} />
                  {userLazyPathways.map(({ path, Component, Fallback }) => (
                    <Route
                      key={path}
                      path={path}
                      element={
                        <Suspense fallback={<Fallback />}>
                          <Component />
                        </Suspense>
                      }
                    />
                  ))} */}
                </>
              )}
            </Routes>
          </div>
        </div>
      ) : (
        // public pathways
        <Routes>
          {/*<Route path='/settings' element={<Settings/>}/>*/}
          <Route index path="/login" element={<AuthPage />} />
          <Route path="*" element={<Navigate to="/login" />} />
          {publicLazyRoutes.map(({ path, Component, Fallback }) => (
            <Route
              key={path}
              path={path}
              element={
                <Suspense fallback={<Fallback />}>
                  <Component />
                </Suspense>
              }
            />
          ))}
        </Routes>
      )}
    </>
  )
})
