import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { observer } from "mobx-react-lite"
// import { useDispatch } from "react-redux"

import { useFormik } from "formik"

import { PasswordInput } from "shared/ui/input/PasswordInput"
import { PhoneInput } from "shared/ui/input/PhoneInput"
import { ErrorMsg } from "shared/ui/input/ErrorMsg"
import { schemes } from "widgets/auth/validations"
import { ContentBar } from "./ContentBar"
import { useStore } from "hooks"
import { Footer } from "shared/ui/footer"

export const Login = observer(() => {
  const navigate = useNavigate()
  const [mounted, setMounted] = useState(false)
  const { auth } = useStore()

  const [error, setError] = useState(null)

  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      phone: "",
      password: "",
    },
    validationSchema: schemes.getSchemeOf(["phone", "password"]),
    onSubmit: async ({ phone, password }) => {
      try {
        // console.log(phone?.match(/[0-9]/g).join("").slice(-9))
        const res = await auth.loadData({ phone: phone?.match(/[0-9]/g).join("").slice(-9), password })
        localStorage.setItem("role_name", res.data.data.user.roles[0]?.name)
        localStorage.setItem("user", JSON.stringify(res.data.data.user))
        if (res?.data?.data?.user?.step === "0") {
          navigate("/settings")
        } else if (res?.data?.data?.user?.step === "1") {
          navigate("/categories")
        } else if (res?.data?.data?.user?.step === "2") {
          navigate("/services")
        } else if (res?.data?.data?.user?.step === "3") {
          navigate("/masters")
        } else {
          navigate("/bookings")
        }

        window.location.reload()

        setMounted(false)
      } catch (e) {
        console.error(error)
      }
    },
  })

  function toggleToChangePassPage() {
    setMounted(false)
    setTimeout(() => {
      navigate("/changePass")
    }, 500)
  }

  function goToSigninPage() {
    setMounted(false)
    setTimeout(() => {
      navigate("/signIn")
    }, 500)
  }

  // animate mounting
  useEffect(() => {
    setMounted(true)
  }, [])


  return (
    <div className="w-full flex items-center justify-center">
      {/*<ContentBar mounted={mounted} />*/}
      <div
        className={`${mounted ? "opacity-100" : "opacity-0"
        } flex items-center  justify-center w-full transition-opacity duration-500 p-2`}
        // } flex-1 flex items-center justify-center transition-opacity duration-500`}
      >
        <div className="max-w-[344px] w-full">
          <div className="text-center max-w-[276px] mx-auto mb-6">
            <h1 className="text-4xl font-medium mb-6">Вход</h1>
          </div>

          <div className="w-full text-left flex flex-col">
            <label
              htmlFor="tel"
              className="label-base flex flex-col [&>input]:bg-white mb-8 relative"
            >
              <span className="mb-1">Номер телефона</span>
              <PhoneInput
                name="phone"
                value={values.phone}
                onChange={handleChange}
                error={errors.phone && touched.phone}
              />
              {errors.phone && touched.phone && <ErrorMsg title={errors.phone} />}
            </label>
            <label className="relative mb-7">
              <PasswordInput
                name="password"
                value={values.password}
                label={"Пароль"}
                placeholder={"Введите пароль"}
                handleValue={handleChange}
                error={touched.password && errors.password}
                onKeyDown={(e) => e.code === "Enter" && handleSubmit()}
              />
            </label>
          </div>
          <div className="text-end">
            <button
              onClick={toggleToChangePassPage}
              className="
                  mb-2 text-right text-darkGray
                  hover:underline h-fit w-fit
                "
            >
              Забыли пароль?
            </button>
            <button
              type="button"
              disabled={auth.checkingIsAuth}
              onClick={handleSubmit}
              className="btnFilled w-full mb-4"
            >
              Войти
            </button>

            <div className="mt-1 text-center relative">
              {error && <p className="text-red absolute -bottom-8 text-center w-full text-lg">{error}</p>}
              {auth.checkingIsAuth &&
                <p className="absolute text-darkGray -bottom-8 text-center w-full text-lg">Выполняется вход...</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
