import { DeleteOutlined } from "@mui/icons-material"

const isMobile = window.screen.width < 500

export function DeleteBtn({ onClick, disabled }) {
  return (
    <button type='button' disabled={disabled} className='h-fit' onClick={onClick} data-title='Удалить'>
      <DeleteOutlined
        fontSize={`${isMobile ? "small" : "medium"}`}
        sx={{
          color: "gray.main",
          "&:hover": { color: "primary.main" },
        }}
      />
    </button>
  )
}
