import { types } from "mobx-state-tree";
import { PagesStore } from "./pages/indexs";
import { INITIAL_STATE } from "constants";
import { AuthStore } from "./AuthStore";

export const RootStore = types.model('RootStore', {
    pages: PagesStore,
    auth: AuthStore,
})

export const getRootStore = () => RootStore.create(INITIAL_STATE)