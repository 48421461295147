import { InputWithIcon } from "../../../shared/ui/input/InputWithIcon"
import { Search } from "@mui/icons-material"
import DatePicker from "react-datepicker"
import { TableSpinner } from "../../../shared/ui/dataTable/spinner"
import { TableFallback } from "../../../widgets/fallbacks/TableFallback"
import AnalyzesTable from "../../../entities/laboratory/analyzes/AnalyzesTable"
import { useGetAnalyzesQuery } from "./analyzesApi"
import { useNavigate } from "react-router-dom"
import { useState } from "react"


const formatDate = (date) => {
  const newDate = new Date(date)
  const year = newDate.getFullYear()
  const month = (newDate.getMonth() + 1).toString().padStart(2, "0") // +1, так как месяцы начинаются с 0
  const day = newDate.getDate().toString().padStart(2, "0")

  return `${year}-${month}-${day}`
}


export const Analyzes = () => {
  const navigate = useNavigate()
  const [search, setSearch] = useState()
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const {data: analyzesData, isLoading: analyzesLoading} = useGetAnalyzesQuery({from: startDate && formatDate(startDate),
    to:  endDate ? formatDate(endDate) : formatDate(startDate),
    search: search})


  return (
    <>
      <div className="sm:rounded-lg sm:bg-white  p-4 ">
        <div className="flex justify-center items-center mb-4">
          <div className="w-full flex-1 mr-0 sm:mr-4">
            <InputWithIcon
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              type="text"
              placeholder={"Поиск по номеру телефона и дате"}
              className="inputOutlined w-full"
              Icon={<Search fontSize="small" className="text-gray" />}
            />
          </div>
          <>
            <DatePicker
              className="inputOutlined mr-4"
              dateFormat="dd-MM-yyyy"
              placeholderText="Период от"
              selected={startDate}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              onChange={date => setStartDate(date)} />
            <DatePicker
              className="inputOutlined mr-4"
              dateFormat="dd-MM-yyyy"
              minDate={startDate}
              placeholderText="Период до"
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              selected={endDate}
              onChange={date => setEndDate(date)} />
          </>
          {/*<button className="p-3 rounded-[6px] flex items-center justify-center border border-[#EDEDED] mr-4"*/}
          {/*        onClick={clearFilters}>*/}
          {/*  <FilterAltOffOutlinedIcon color={"disabled"} />*/}
          {/*</button>*/}

          <button onClick={() => navigate('/analyzes/new')}
            className="btnFilled px-4 sm:flex hidden"
          >
            Добавить анализ
          </button>
        </div>
        <button
          className="sm:hidden block btnAddContent fixed right-4 bottom-[80px] z-10"
        >
          +
        </button>


        {/*{activeNewBooking && (*/}
        {/*  <AddBooking active={activeNewBooking} setActive={setActiveNewBooking} />*/}
        {/*)}*/}

        {analyzesLoading ? (
          <div className="w-full min-h-[300px] flex justify-center items-center">
            <TableSpinner />
          </div>
        ) : analyzesData?.results?.length ? (
          <AnalyzesTable analyzes={analyzesData?.results} />
        ) : (<TableFallback text={"Список записей пуст"} />)}
      </div>
    </>
  )
}

