import { useEffect, useState } from 'react'
import { MenuItem, Pagination, Select } from '@mui/material'

import { SelectOutlined } from 'shared/ui/select/SelectOutlined'
import { observer } from 'mobx-react-lite'

export const PaginationBlock = observer(({ setRowsPerPage, setPage, currentPage, pagesCount, perPage }) => (
  <div className='flex md:justify-end justify-center'>
    <div className='
        flex lg:flex-row items-center justify-end
        md:flex-row flex-col sm:text-base text-sm
      '>
      {/* // @DEBUG(TODO) Will be re-implemented in future */}
      {/* <div className='flex items-center'>
        <p className='whitespace-nowrap mr-3'>Строк на странице:</p>
        <div className='w-fit'>
          <SelectOutlined
            value={perPage}
            handleChange={perPage => setRowsPerPage(perPage)}
          >
            {[5, 10, 20, 50].map((item) => (
              <MenuItem key={item} selected={item === perPage} value={item}>{item}</MenuItem>
            ))}
          </SelectOutlined>
        </div>
      </div> */}
      {
        pagesCount > 1 && (
          <div className='w-full'>
            <div className='w-full flex justify-end'>
              <Pagination
                color='secondary'
                sx={{
                  "& .MuiPaginationItem-root": {
                    color: 'gray.main',
                    '&.Mui-selected': {
                      color: 'white',
                    }
                  },
                }}
                count={pagesCount}
                page={currentPage}
                shape="rounded"
                onChange={(_, page) => setPage?.(page)}
              />
            </div>
          </div>
        )
      }
    </div>
  </div>
))
