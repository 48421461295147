import React, { useState } from "react"
import { Close, VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material"
import {changePass} from "./changePassSlice.ts"

import { Modal } from "shared/ui/modal/Modal"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-hot-toast"

export function ChangePass({ active, setActive }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {loading, error, success} = useSelector(state => state.changePassSliceReducer)
  const [passwordError, setPasswordError] = useState(false)
  const [visible1, setVisible1] = useState(false)
  const [visible2, setVisible2] = useState(false)
  const [visible3, setVisible3] = useState(false)
  function toggleVisibility() {
    setVisible1(!visible1)
  }
  function toggleVisibility2() {
    setVisible2(!visible2)
  }
  function toggleVisibility3() {
    setVisible3(!visible3)
  }

  const { register, handleSubmit, formState: { errors } } = useForm()

  const onSubmit = (data) => {
    if(data.password !== data.password_confirmation) {
      setPasswordError(true)
      return
    }
    dispatch(changePass(data))
  }



  return (
    <Modal active={active} setActive={setActive} className="max-w-sm">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-[20px] font-bold">Смена пароля</h1>
          </div>
          <button type="button" className="text-gray hover:text-black" onClick={() => setActive(false)}><Close />
          </button>
        </div>
        <div className="mt-4">

          <>
            <label
              htmlFor='confPass'
              className='label-base flex flex-col relative'
            >
              Текущий пароль
              <input
                {...register('current_password', {
                  required: 'Заполните это поле'
                })}
                color='primary'
                className="inputOutlined relative mt-1 pr-12"

                type={visible1 ? "text" : "password"}
              />
              <div
                onClick={toggleVisibility}
                className='w-fit absolute right-3 top-[47%] cursor-pointer'
              >
                {visible1 ? (
                  <VisibilityOutlined sx={{ width: "18px" }} />
                ) : (
                  <VisibilityOffOutlined sx={{ width: "18px" }} />
                )}
              </div>
            </label>
          </>
          {errors?.current_password &&
            <span className="text-[12px] text-red">{errors?.current_password?.message}</span>}
        </div>

        <div className="mt-4">
          <>
            <label
              htmlFor='confPass'
              className='label-base flex flex-col relative'
            >
              Новый пароль
              <input
                {...register('password', {
                  required: 'Заполните это поле',
                  minLength: {
                    value: 8,
                    message: 'Пароль должен быть длиннее 8 символов'
                  }
                })}
                color='primary'
                className="inputOutlined relative mt-1 pr-12"

                type={visible2 ? "text" : "password"}
              />
              <div
                onClick={toggleVisibility2}
                className='w-fit absolute right-3 top-[47%] cursor-pointer'
              >
                {visible2 ? (
                  <VisibilityOutlined sx={{ width: "18px" }} />
                ) : (
                  <VisibilityOffOutlined sx={{ width: "18px" }} />
                )}
              </div>
            </label>
          </>
          {errors?.password && <span className="text-[12px] text-red">{errors?.password?.message}</span>}

          <>
            <label
              htmlFor='confPass'
              className='label-base flex flex-col relative mt-4'
            >
              Подтвердите новый пароль
              <input
                {...register('password_confirmation', {
                  required: 'Заполните это поле'
                })}
                color='primary'
                className="inputOutlined relative mt-1 pr-12"

                type={visible3 ? "text" : "password"}
              />
              <div
                onClick={toggleVisibility3}
                className='w-fit absolute right-3 top-[47%] cursor-pointer'
              >
                {visible3 ? (
                  <VisibilityOutlined sx={{ width: "18px" }} />
                ) : (
                  <VisibilityOffOutlined sx={{ width: "18px" }} />
                )}
              </div>
            </label>
          </>
          {passwordError && <span className="text-[12px] text-red">Пароли не совпадают</span>}
          {errors?.password_confirmation && <span className="text-[12px] text-red">{errors?.password_confirmation?.message}</span>}

          <button
            type="submit"
            disabled={loading}
            className={loading ? "opacity-50 btnFilled w-full mt-10 disabled:bg-gray" : "opacity-100 btnFilled w-full mt-10 disabled:bg-gray"}>
            Сохранить
          </button>
        </div>
      </form>
    </Modal>
  )
}