import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { getApiConfig } from "shared/api"

const { BASE_URL, accessToken } = getApiConfig()
const ME_PATH = "me"

function getMeApi() {
  return axios
    .get(`${BASE_URL}/${ME_PATH}`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then(({ data }) => data.data)
}

export const getMeData = createAsyncThunk("main/getMeData",
  async (undefined, {rejectWithValue}) => {
    try {
      const { user } = await getMeApi()
      return user
    } catch (error) {
      return rejectWithValue(error)
    }
})

export const mainSlice = createSlice({
  name: "main",
  initialState: {
    isAdmin: true,
    isAuth: false,
    user: null,
    checkingIsAuth: false
  },
  reducers: {
    updatedIsAuth: (state, { payload: { isAuth, user } }) => {
      state.isAuth = isAuth
      if (!isAuth) {
        localStorage.removeItem("accessToken")
      }
      if (user) state.user = user
    },
    setPermission: (state, { payload }) => {
      state.isAdmin = payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getMeData.pending, state => {
        state.checkingIsAuth = true
      })
      .addCase(getMeData.fulfilled, (state, { payload }) => {
        state.user = payload
        state.isAuth = true
        state.checkingIsAuth = false
      })
      .addCase(getMeData.rejected, (state, { payload }) => {
        state.user = null
        state.isAuth = false
        state.checkingIsAuth = false
      })
  }
})

export const { updatedIsAuth, setPermission } = mainSlice.actions