import { useState } from "react"
import { GoBackBtn } from "../../../../widgets/auth/GoBackBtn"

export const SelectSalonType = ({ submit, backSlide, hidden3 }) => {
  const [salon, setSalon] = useState(false)
  const [person, setPerson] = useState(false)
  const [error, setError] = useState(false)

  function select(e) {
    if (e.target.value === "Салон") {
      setSalon(true)
      setPerson(false)
    } else {
      setPerson(true)
      setSalon(false)
    }
    setError(false)
  }

  function onSubmit() {
    if (salon || person) {
      setError(false)
      submit(salon ? 1 : 2)
    } else setError(true)
  }

  return (
    <div className={`max-w-[344px] w-full ${hidden3 && 'hidden'}`}>
      <div className="text-center mx-auto mb-7">
        <h1 className="text-[27px] text-center font-medium mb-6">
          Выберите тип аккаунта
        </h1>
      </div>

      <div
        className={`max-w-fit mx-auto
        flex flex-col font-medium [&>input]:cursor-pointer [&>input]:border-[1px] [&>input]:rounded-lg
        [&>input]:text-blue [&>input]:font-normal [&>input]:border-transparent
        [&>input]:px-2 [&>input]:py-1 mb-12 [&>input:hover]:bg-lightOrange [&>input:hover]:text-orange
      `}
      >
        <input
          onClick={select}
          type={"button"}
          className={`mb-2 ${
            salon && "bg-orange/[24%] !text-orange hover:!bg-orange/[24%]"
          }`}
          value={"Салон"}
        ></input>
        <input
          onKeyDown={(e) => e.code === "Tab" && e.preventDefault()}
          onClick={(e) => select(e)}
          type={"button"}
          value={"Частный мастер"}
          className={`${
            person && "bg-orange/[24%] !text-orange hover:!bg-orange/[24%]"
          }`}
        ></input>
      </div>

      <div className="relative">
        {error && (
          <p
            className="
              text-sm text-red absolute text-center whitespace-nowrap w-full left-0 right-0 -bottom-8
            "
          >
            Выберите один из вариантов
          </p>
        )}
        <button
          type="button"
          onClick={onSubmit}
          className="btnFilled w-full disabled:bg-gray mb-3"
        >
          Далее
        </button>
        <GoBackBtn handleClick={backSlide} />
      </div>
    </div>
  )
}
