const DEFAULT_AVATAR_IMAGE =
  "//static.vecteezy.com/system/resources/previews/005/276/776/original/logo-icon-person-on-white-background-free-vector.jpg"

export const viewModel = {
  getDefaultModel() {
    return {
      name: "",
      surname: "",
      email: "",
      phone: "",
      gender: "",
      job: "",
      category: "Стрижка",
      service: "Услуга1",
      website: "",
      facebook: "",
      instagram: "",
      password: "",
      adminPermission: false,
      avatar: {
        id: null,
        original_url: DEFAULT_AVATAR_IMAGE,
      },
      portfolio: [],
      services: [],
      mode: "add",
      show_calendar: "1",
    }
  },
  getEditModel(master) {
    return { ...master, mode: "edit" }
  },
}
