import {Skeleton} from "@mui/material";

export function TableSkeleton() {
  return (
    <>
      <div className="w-full h-full p-4 flex flex-col bg-white rounded-lg">
        {/* search bar */}
        <div className="flex mb-10 justify-between">
          <Skeleton animation='wave' variant="rounded" width='78%' height='60px'/>
          <Skeleton animation='wave' variant="rounded" width='20%' height='60px'/>
        </div>
        {/* table */}
        <div className="flex flex-col w-full h-full justify-between">
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(n => (
            <Skeleton key={n} animation='wave' variant="rounded" width='100%' height='7%'/>
          ))}
        </div>
      </div>
    </>
  )
}