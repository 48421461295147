import { ChevronLeft } from "@mui/icons-material"

export const GoBackBtn = ({ handleClick }) => {
  return (
    <>
      <button
        onClick={handleClick}
        className="
        text-gray hover:bg-lightOrange hover:text-orange font-normal cursor-pointer
        flex items-center justify-center my-0 mx-auto h-fit px-3 py-2 rounded-lg
      "
      >
        <p className="h-fit w-fit flex pr-2 justify-center items-center">
          <ChevronLeft /><span>Назад</span>
        </p>
      </button>
    </>
  )
}