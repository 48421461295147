import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import {currentUrl} from '../../url.js'

const accessToken = localStorage.getItem('accessToken')

type ISphere = {
  category: {
    id: number;
    name: string;
  }
}

interface ISpheresState {
  spheres: ISphere[],
  spheresLoading: boolean,
  spheresError: boolean
}

export const fetchSpheres = createAsyncThunk<ISphere[], undefined, {rejectValue: boolean}> (
  'spheres/fetchSpheres',
  async function(_, {rejectWithValue}) {
    if(accessToken) {
      const res = await fetch(`${currentUrl}/master/categories`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      if(!res) {
        return rejectWithValue(true)
      }

      const data = await res.json()
      const processedData: ISphere[] = data.map((item): ISphere => ({
        category: {
          id: item?.category?.id,
          name: item?.category?.name
        }
      }))

      return processedData
    }
  }
)

const initialState: ISpheresState = {
  spheres: [],
  spheresLoading: false,
  spheresError: false
}

const spheresSlice = createSlice({
  name: 'spheresSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSpheres.pending, (state) => {
        state.spheresLoading = true
        state.spheresError = false
      })
      .addCase(fetchSpheres.fulfilled, (state, action) => {
        state.spheres = action.payload
        state.spheresLoading = false
      })
      .addCase(fetchSpheres.rejected, (state) => {
        state.spheresError = true
      })
  }
})

export default spheresSlice.reducer
