import { KeyboardDoubleArrowLeftOutlined } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";

const isMobile = window.screen.width < 500;

export function MobileSidebar({ pathList, toggleNavbar, isHidden }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  function navigateTo(path) {
    navigate(path);
    setTimeout(() => {
      toggleNavbar();
    }, 300);
  }

  return (
    <>
      <div
        onClick={(e) => e.stopPropagation()}
        className={`${
          isHidden ? "translate-x-[-110%]" : "translate-x-0"
        } transition-transform duration-300 sm:hidden flex fixed top-0 left-0 bottom-0 z-[100]`}
      >
        <div className=' h-full bg-blue px-6 [&>a]:mb-5 w-fit z-10'>
          <div className='flex items-center justify-between'>
            <div className='w-fit h-fit flex text-orange sm:text-2xl text-xl my-7'>
              <img src='./assets/logo/logo.svg' alt='Logo' />
            </div>
            <button
              onClick={toggleNavbar}
              className='text-white hover:text-orange h-fit [&>svg]:w-[20px]'
            >
              <KeyboardDoubleArrowLeftOutlined className='w-[20px] text-orange' />
            </button>
          </div>
          {pathList.map(({ path, value, Icon }) => (
            <button
              key={path}
              onClick={() => navigateTo(path)}
              className={`${
                pathname === path
                  ? "text-orange [&>div]:text-orange"
                  : "text-white"
              }
              flex items-center w-full whitespace-nowrap
              sm:text-lg font-normal hover:text-orange [&>div]:text-gray group
            `}
            >
              <div>
                {
                  <Icon
                    sx={{
                      width: "20px",
                      marginRight: "15px",
                      color: `${pathname === path && "secondary.main"}`,
                    }}
                  />
                }
              </div>
              <span className='w-fit'>{value}</span>
            </button>
          ))}
        </div>
      </div>
      {isMobile && (
        <div
          onTouchStartCapture={(e) => setTimeout(toggleNavbar, 100)}
          className={`sidebar absolute left-0 top-0 right-0 bottom-0 bg-blue/30 z-20 ${
            isHidden ? "invisible opacity-0" : "visible opacity-100"
          } transition-opacity`}
        ></div>
      )}
    </>
  );
}
