import * as yup from "yup"

export const schemes = {
  getSchemeOf(options = []) {
    // options: <string>[]

    // проходим по массиву, создаём объекты с ключами из элементов массива и значениями из свойст schemes
    // {[item]: this[item]} === {email: this.email}
    // затем помещаем элементы получившегося массива в 1 объект:
    // [{email: this.email}, {pass: this.pass}] => {email: this.email, pass: this.pass}
    let shapes = {}
    options.forEach((item) => {
      shapes = { ...shapes, [item]: schemes[item] }
    })

    const scheme = yup.object().shape(shapes)
    return scheme
  },

  password: yup
    .string()
    .min(8, "Не менее 8 символов")
    .required("Это обязательное поле"),

  repeatPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Пароли не совпадают")
    .required("Это обязательное поле"),

  phone: yup
    .string()
    .test("isFullfilled", "Заполните поле полностью", (val) => {
      return val?.length >= 16 && Number.isInteger(Number(val[val.length - 1]))
    })
    .required("Это обязательное поле"),

  // confirmation code
  code: yup
    .string()
    .min(4, "введите код корректно")
    .max(4, "введите код корректно")
    .required("Это обязательное поле"),

  name: yup
    .string()
    .required("Поле является обязательным")
    .min(3, "Введите не менее 3 символов")
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\u0430-\u044f\s]*)$/gi,
      "Можно вводить только буквы"
    ),

  surname: yup
    .string()
    .min(3, "Введите не менее 3 символов")
    .matches(
      /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\u0430-\u044f\s]*)$/gi,
      "Можно вводить только буквы"
    ),

  email: yup
    .string()
    .required("Поле является обязательным")
    .email("Введите корректный email"),
}
