import { useEffect, useState } from "react"
import { Button, FormControlLabel, Radio, RadioGroup } from "@mui/material"

import { Controller, useForm } from "react-hook-form"
import { setSettings, useEditProfileMutation, useGetMeQuery } from "./userProfileApi"
import { InputBase } from "../../shared/ui/input/InputBase"
import { toast } from "react-hot-toast"
import { useDropzone } from "react-dropzone"
import { PhotoCameraOutlined } from "@mui/icons-material"
import { usePostAvatarMutation } from "../masters/slice"
import { useDispatch, useSelector } from "react-redux"
import { TableSpinner } from "../../shared/ui/dataTable/spinner"

const user = JSON.parse(localStorage.getItem("user"))
const userId = user?.id
const salonId = user?.salon?.id

export default function UserProfile() {
  const dispatch = useDispatch()
  const { settings } = useSelector(state => state.profileReducer)
  const [photo, setPhoto] = useState([])
  const [selectedPhoto, setSelectedPhoto] = useState(null)
  const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()

  const { getRootProps: avatarRootProps, getInputProps: avatarInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0]
        setSelectedPhoto(file)
      }
    },
  })

  const { data: settingsData, isLoading: settingsLoading } = useGetMeQuery({ id: userId })
  const [editSettings, { isLoading: postLoading }] = useEditProfileMutation()
  const [postAvatar, { isLoading: avatarLoading }] = usePostAvatarMutation()


  useEffect(() => {
    dispatch(setSettings(settingsData?.data))
  }, [settingsLoading, settingsData, dispatch])

  useEffect(() => {
    if(!settingsLoading) {
      setValue("name", settings?.name)
      setValue("surname", settings?.surname)
      setValue("gender", settings?.gender)
      setValue("email", settings?.email)
      setValue("position", settings?.position || "Должность")
      if (settings?.avatar) {
        const reversedAvatar = [...settings?.avatar].reverse()
        setPhoto(reversedAvatar)
      }
    }
  }, [setValue, settings, settingsLoading])


  const onSubmit = async (data) => {
    try {
      await editSettings({ id: userId, ...data }).unwrap()
      if (selectedPhoto) {
        try {
          const formData = new FormData()
          formData.append("file", selectedPhoto)
          formData.append("collection_name", "avatar")
          formData.append("model_id", userId)
          await postAvatar({ id: salonId, formData }).unwrap()
        } catch (err) {
          toast.error("Ошибка при загрузке аватара", { position: "bottom-right" })
        }
      }
      toast.success("Изменения сохранены", { position: "bottom-right" })
    } catch (err) {
      toast.error("Ошибка при сохранении изменений, попробуйте позже", { position: "bottom-right" })
    }
  }

  return (
    <>
      {settingsLoading ?
        <div className="w-full min-h-[300px] flex justify-center items-center">
          <TableSpinner />
        </div>
        :
        <form onSubmit={handleSubmit(onSubmit)}
              className="
      mt-8 [&>div>label]:flex [&>div>label]:flex-col [&>label>input]:mt-1
      sm:[&>div]:grid sm:[&>div]:grid-cols-3 [&>h2]:mb-5 p-4 sm:p-0
    "
        >
          <div className="flex mb-9 col-span-3">
            <div className="flex items-end w-32 mr-8">
              <img
                className="w-full h-32 object-cover rounded-lg"
                src={selectedPhoto ? URL.createObjectURL(selectedPhoto) : settings?.avatar ? photo[0]?.original_url || `//static.vecteezy.com/system/resources/previews/005/276/776/original/logo-icon-person-on-white-background-free-vector.jpg` : ""}
                alt="avatar"
              />
              <div className="ml-3">
                <div className="pb-3">
                  <h3 className="pb-3">Должность:</h3>
                  <p className="whitespace-nowrap font-semibold">{settings?.position}</p>
                </div>
                <div>
                  <Button
                    {...avatarRootProps()}
                    fullWidth
                    sx={{
                      fontWeight: 500,
                      "&:hover": {
                        bgcolor: "secondary.main",
                        color: "white",
                      },
                    }}
                    variant="outlined"
                    color="secondary"
                    startIcon={<PhotoCameraOutlined />}
                  >
                    <input
                      {...avatarInputProps()}
                      id="upload"
                      hidden
                      accept="image/*"
                      type="file"
                    />
                    Изменить
                  </Button>
                </div>
              </div>

            </div>
          </div>

          <h2 className="title mb-5">Личные данные</h2>
          <div>
            <div>
              <Controller
                control={control}
                name="name"
                rules={{
                  required: "Заполните это поле",
                  minLength: {
                    value: 2,
                    message: "Введите минимум 2 символа",
                  },
                }}
                render={({ field }) => (
                  <InputBase
                    {...field}
                    inputClassName="inputOutlined"
                    labelClassName="sm:mr-5"
                    labelText="Имя"
                  />
                )} />
              {errors.name && <span className="text-[14px] text-red">{errors?.name?.message}</span>}
            </div>

            <label className="label-base sm:mr-6">
              Фамилия
              <input
                {...register("surname", {
                  required: "Заполните это поле",
                  minLength: {
                    value: 2,
                    message: "Введите минимум 2 символа",
                  },
                })}
                type="text"
                className="inputOutlined"
              />
              {errors.surname && <span className="text-[14px] text-red">{errors?.surname?.message}</span>}
            </label>


            <label className="label-base">
              Электронная почта
              <input
                {...register("email",
                  {
                    required: "Заполните это поле",
                  })}
                type="email"
                placeholder="example@gmail.com"
                className="inputOutlined"
              />
              {errors.email && <span className="text-[14px] text-red">{errors?.email?.message}</span>}
            </label>

          </div>

          <div className="mt-8 h-fit !flex flex-col mb-5">
            <span>Пол</span>
            <div
              className="
          text-darkGray text-base font-normal flex items-center h-full mt-2
        "
            >
              <div className="mr-6 flex items-center">

                <Controller
                  defaultValue={settings?.gender || 'male'}
                  name="gender"
                  rules={{
                    required: "Выберите пол",
                  }}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <RadioGroup
                      value={value}
                      onChange={onChange}
                    >
                      <div className="flex ml-2 items-center">
                        <FormControlLabel
                          value="male"
                          control={
                            <Radio
                              color="secondary"
                              size="small"
                              sx={{ color: "#A4AFBD", padding: 0, marginRight: 1 }}
                            />
                          }
                          label="Мужской" />
                        <FormControlLabel
                          value="femele"
                          control={
                            <Radio
                              color="secondary"
                              size="small"
                              sx={{ color: "#A4AFBD", padding: 0, marginRight: 1, marginLeft: 2 }}
                            />
                          }
                          label="Женский" />
                        {errors.gender && <span className="text-[14px] text-red">{errors?.gender?.message}</span>}
                      </div>
                    </RadioGroup>
                  )} />

              </div>
            </div>
          </div>

          <button disabled={avatarLoading || postLoading} type="submit"
                  className={avatarLoading || postLoading ? "opacity-50 btnFilled w-fit px-4 py-3 my-10" : "opacity-100 btnFilled w-fit px-4 py-3 my-10"}>Сохранить
          </button>
        </form>
      }
    </>
  )
}
