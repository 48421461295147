import { ErrorMsg } from "./ErrorMsg"
import React from 'react'

export const InputBase = React.memo(React.forwardRef((props, ref) => {
  const { name = "",
    type = "text",
    value = "",
    disabled,
    required,
    onChange = () => null,
    placeholder,
    inputClassName = "",
    labelClassName = "",
    labelText = "",
    errorText = "",
    errorCondition = false,} = props


  return (
    <label className={`${labelClassName} flex flex-col label-base relative`}>
      {labelText}{required && '*'}
      <input
        ref = { ref }
        name={name}
        type={type}
        disabled={disabled}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`${inputClassName} ${errorCondition && "border-red-500 hover:border-red-500 focus:border-red-500"}`}
      />
      {errorCondition && <ErrorMsg title={errorText} />}
    </label>
  )
}))


