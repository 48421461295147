import { Controller } from "react-hook-form"
import React from 'react'

export const MyController = React.forwardRef((props, ref) => {
  return (
    <Controller
      {...props}
      ref={ref}
    />
  )
})