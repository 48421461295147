import ReactInputMask from "react-input-mask"
import { forwardRef } from "react"

export const PhoneInput = forwardRef(({
  value,
  onChange,
  disabled,
  error,
  autoFocus,
  className,
  name,
  onKeyDown,
}, ref) => {

  return (
    <>
      <ReactInputMask
        mask='+\9\92-999-999-999'
        maskPlaceholder={""}
        placeholder='+992 Введите ваш номер телефона'
        name={name || "phone"}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        disabled={disabled}
      >
        <input
          ref={ref}
          type='text'
          className={`inputOutlined w-full ${
            error && "border-red-500 focus:border-red-500 "
          } ${className}`}
        />
      </ReactInputMask>
    </>
  )
})
