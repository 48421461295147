import { Checkbox } from "@mui/material"

export function MyCheckbox({ name, value, onChange, className, inner }) {
  return (
    <div className={className}>
      <Checkbox
        sx={{
          padding: 0,
          marginRight: 0.5,
          color: "gray.main",
          "&.Mui-checked": {
            color: "secondary.main",
          },
        }}
        value={value}
        onChange={onChange}
        name={name}
        checked={value}
      />{" "}
      {inner}
    </div>
  )
}