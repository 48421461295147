import { useRef, useState } from "react"
import { Link, NavLink, useLocation } from "react-router-dom"
import "./style.css"

import {
  KeyboardDoubleArrowLeftOutlined,
  KeyboardDoubleArrowRightOutlined,
} from "@mui/icons-material"
import { useSelector } from "react-redux"
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined"
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const user = JSON.parse(localStorage.getItem("user"))
const isAdmin = user?.admin
const category_id = user?.salon?.category_id
export const Navbar = ({ pathList, hidden, setHidden }) => {
  const {step} = useSelector(state => state.StepSliceReducer)
  const wrapRef = useRef(null)
  const { pathname } = useLocation()

  const [showMenu, setShowMenu] = useState(false)

  function entered() {
    setHidden(false)
    wrapRef.current.classList.remove("leaveWidth")
    wrapRef.current.classList.add("enterWidth")
  }

  function leaved() {
    setHidden(true)
    wrapRef.current.classList.remove("enterWidth")
    wrapRef.current.classList.add("leaveWidth")
  }

  const openMenu = (e) => {
    e.stopPropagation()
    setShowMenu(!showMenu)
  }

  return (
    <>
      <div
        onClick={() => setShowMenu(false)}
        // onMouseEnter={entered}
        // onMouseLeave={leaved}
        className="hidden sm:flex bg-blue px-6"
      >
        <div
          ref={wrapRef}
          className="sticky left-0 top-0 bottom-0 h-screen navContent overflow-y-auto"
        >
          <div className=" mb-16 flex justify-end items-center">

            {/*<button*/}
            {/*  onClick={hidden ? entered : leaved}*/}
            {/*  className="[&>svg]:text-lightGray [&>svg]:hover:text-orange h-fit [&>svg]:w-[20px]"*/}
            {/*>*/}
            {/*  {hidden ? (*/}
            {/*    <KeyboardDoubleArrowRightOutlined*/}
            {/*      className="w-[20px]"*/}
            {/*      fontSize="small"*/}
            {/*    />*/}
            {/*  ) : (*/}
            {/*    <KeyboardDoubleArrowLeftOutlined className="w-[20px]" />*/}
            {/*  )}*/}
            {/*</button>*/}
          </div>
          <nav className="flex flex-col">

            {category_id === 2 &&
              <>
              <div
              onClick={(e) => openMenu(e)}
              className={`text-white
                  flex items-center w-full whitespace-nowrap mb-3 sm:mb-7
                  sm:text-lg font-normal hover:text-orange [&>div]:text-gray group
                  cursor-pointer
                `}
            >
              <div className="group-hover:text-orange">
                {
                  <AssignmentOutlinedIcon
                    sx={{
                      width: "20px",
                      marginRight: hidden ? "0px" : "15px",
                      color: `${pathname === "/laboratory" && "secondary.main"}`,
                    }}
                  />
                }
              </div>
              {!hidden && (
                <>
                  <p
                    className={`transitional transitionAppearance w-fit pr-[10px]`}
                  >
                    Лаборатория
                  </p>
                  <div className='text-white hover:text-orange'>
                    {showMenu ? <KeyboardArrowDownIcon/> : <KeyboardArrowRightIcon />}
                  </div>
                </>

              )}
            </div>
            {showMenu &&
              <div
              onClick={e => e.stopPropagation()}
            className="ml-6 flex flex-col justify-center items-center">
            <Link
              to="/templates"
              className={`${
                pathname === "/templates"
                  ? "text-orange [&>div]:text-orange"
                  : "text-white"
              }
                  flex items-center w-full whitespace-nowrap mb-3 sm:mb-7
                  sm:text-lg font-normal hover:text-orange [&>div]:text-gray group
                `}
            >
              Шаблоны
            </Link>
            <Link
              to='/analyzes'
              className={`${
                pathname.includes('/analyzes')
                  ? "text-orange [&>div]:text-orange"
                  : "text-white"
              }
              flex items-center w-full whitespace-nowrap mb-3 sm:mb-7
              sm:text-lg font-normal hover:text-orange [&>div]:text-gray group
            `}
            >
              Анализы
            </Link>
        </div>
        }
            </>}


            {pathList.map(({ path, value, Icon }) => (
              (isAdmin === '0' && value === 'Настройки') ?
                null :
              <NavLink
                key={path}
                to={path}
                className={`${ pathname === path ? "text-orange [&>div]:text-orange" :       
                  step === "0" && value === "Настройки" ? "hover:text-orange [&>div]:text-gray group text-white" : step === "1" && (value === "Категории услуг" || value === "Настройки") ? "hover:text-orange [&>div]:text-gray group text-white" : step === "2" && (value === "Категории услуг" || value === "Услуги" || value === "Настройки") ?
                  "hover:text-orange [&>div]:text-gray group text-white" : step === "3" && (value === "Категории услуг" || value === "Услуги" || value === "Настройки" || value === 'Сотрудники') ?  "hover:text-orange [&>div]:text-gray group text-white" : step === "4" || step === '5' ? "hover:text-orange [&>div]:text-gray group text-white" : "text-[#9a9c9a] pointer-events-none" }
                  flex items-center w-full whitespace-nowrap mb-3 sm:mb-7 sm:text-lg font-normal        
                `}
              >
                <div className="group-hover:text-orange">
                  {
                    <Icon
                      sx={{
                        width: "20px",
                        marginRight: hidden ? "0px" : "15px",
                        color: `${pathname === path && "secondary.main"}`,
                      }}
                    />
                  }
                </div>
                {!hidden && (
                  <span
                    className={`transitional transitionAppearance w-fit pr-[10px]`}
                  >
                    {value}
                  </span>
                )}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
    </>
  )
}
