import { createSlice } from "@reduxjs/toolkit"


const initialState = {
  step: ''
}

const stepSlice = createSlice({
  name: 'stepSlice',
  initialState,
  reducers: {
    setStep (state, action) {
      state.step = action.payload
    }
  }
})

export const {setStep} = stepSlice.actions

export default stepSlice.reducer
