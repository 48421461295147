import { useFormik } from "formik"
import * as yup from "yup"

import { PhoneInput } from "shared/ui/input/PhoneInput"
import { GoBackBtn } from "widgets/auth/GoBackBtn"
import { schemes } from "widgets/auth/validations"
import { ErrorMsg } from "shared/ui/input/ErrorMsg"
import { useEffect, useState } from "react"
import Modal from "../../../../entities/auth/TermsOfUseModal"
import axios from "axios"
import { currentUrl } from "../../../../url"

export const PersonalInfo = ({ submit, backSlide, errorMsg }) => {
  const [checkboxState, setCheckboxState] = useState({
    checkbox1: false,
    checkbox2: false,
  })
  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState("")
  const [emailError, setEmailError] = useState(false)
  const [email, setEmail] = useState("")

  const scheme = yup.object().shape({
    name: schemes.name,
    surname: schemes.name,
    phone: schemes.phone,
    email: schemes.email
  })
  const { values, handleChange, handleSubmit, errors, touched } = useFormik({
    initialValues: {
      name: "",
      surname: "",
      phone: "",
      email: ""
    },
    validationSchema: scheme,
    validateOnMount: true,
    onSubmit: (data) => {
      submit({ ...data })
      setTimer(5)
    },
  })

  const handleConfidClick = () => {
    setTitle("Политика конфиденциальности")
    setOpen(true)
  }

  async function nextStep() {
    setEmailError(false)
    try {
      const res = await axios.get(`${currentUrl}/master/unique-mail?email=${values?.email}`)
      if (!res.data.unique) {
        setEmailError(true)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const validateEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
    return emailRegex.test(email)
  }

  useEffect(() => {
    if (values?.email && validateEmail(values?.email)) {
      nextStep()
    }
  }, [values?.email])


  const handleUserAgreementClick = () => {
    setTitle("Пользовательское соглашение")
    setOpen(true)
  }
  const handleCheckboxChange = (name) => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }))
  }


  const [timer, setTimer] = useState(0)

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1)
      }, 1000)
    }
  }, [timer])

  return (
    <>
      <div
        className="max-w-[450px] w-full relative mt-[-40px]"
      >
        <div className="text-center mx-auto mb-5">
          <h1 className="text-[27px] font-medium mb-3">Персональные данные</h1>
          <p className="text-darkGray font-normal">
            Введите свой номер телефона для дальнейший работы с нами
          </p>
        </div>
        <div className="grid grid-cols-2 mb-4 whitespace-nowrap text-ellipsis">
          <label className="label-base flex flex-col mr-2 relative">
            <p>
              Имя <span className="text-red">*</span>
            </p>
            <input
              name="name"
              placeholder="Введите имя"
              value={values.name}
              onChange={handleChange}
              className="inputOutlined"
            />
            {errors.name && touched.name && (
              <ErrorMsg
                className="overflow-hidden whitespace-nowrap text-ellipsis"
                title={errors.name}
              />
            )}
          </label>

          <label className="label-base flex flex-col ml-2 relative">
            <p>
              Фамилия <span className="text-red">*</span>
            </p>
            <input
              name="surname"
              placeholder="Введите фамилию"
              value={values.surname}
              onChange={handleChange}
              className="inputOutlined"
            />
            {errors.surname && touched.surname && (
              <ErrorMsg
                className="whitespace-nowrap overflow-hidden text-ellipsis"
                title={errors.surname}
              />
            )}
          </label>
        </div>
        <div className="w-full text-left mb-2">
          <label className="label-base flex flex-col mb-4 relative">
            <p>
              Электронная почта <span className="text-red">*</span>
            </p>
            <input
              name="email"
              type="email"
              placeholder="example@gmail.com"
              value={values.email}
              onChange={handleChange}
              className="inputOutlined"
            />
            {errors.email && touched.email && <div className='mb-2'><p className='text-red ml-1'>{errors.email}</p></div>}
            {emailError && <ErrorMsg title="Этот email уже занят" />}
          </label>

          <label className="label-base flex flex-col relative">
            <p className="mb-1">
              Номер телефона <span className="text-red">*</span>
            </p>
            <PhoneInput
              name={"phone"}
              value={values.phone}
              onChange={handleChange}
              error={errors.phone && touched.phone}
            />
            {errors.phone && touched.phone && <ErrorMsg title={errors.phone} />}
          </label>
        </div>

        <form className="flex flex-col w-fit">
          <div className="flex items-start gap-2">
            <input
              type="checkbox"
              name="confidentiality"
              id="confidentiality"
              checked={checkboxState.checkbox1}
              onChange={() => handleCheckboxChange("checkbox1")}
              className="rounded border-gray-300 checked:bg-[#EE6F42] hover:cursor-pointer hover:text-[#EE6F42] focus:text-[#EE6F42]"
            />
            <label
              htmlFor="confidentiality"
              className="hover:cursor-pointer mt-3"
            >
              Я прочитал(а) и принимаю условия{" "}
              <span onClick={handleConfidClick}
                    className="text-[#EE6F42] hover:underline cursor-pointer"
              >Политики конфиденциальности
              </span>
              {" "}
              и{" "}
              <span onClick={handleUserAgreementClick}
                    className="text-[#EE6F42] hover:underline cursor-pointer"
              >Пользовательского соглашения
              </span>
              .
            </label>
          </div>
          <div className="flex items-start gap-2">
            <input
              type="checkbox"
              name="personal"
              id="personal"
              checked={checkboxState.checkbox2}
              onChange={() => handleCheckboxChange("checkbox2")}
              className="rounded border-gray-300 checked:bg-[#EE6F42] hover:cursor-pointer hover:text-[#EE6F42] focus:text-[#EE6F42]"
            />
            <label
              htmlFor="personal"
              className="hover:cursor-pointer mt-3"
            >
              Я выражаю согласие на обработку моих персональных данных в
              соответствии с условиями согласия.
            </label>
          </div>
        </form>

        <button
          type="button"
          disabled={!checkboxState.checkbox1 || !checkboxState.checkbox2 || emailError}
          onClick={handleSubmit}
          className={!checkboxState.checkbox1 || !checkboxState.checkbox2 || emailError ? "opacity-[50%] btnFilled w-full mt-2" : "btnFilled w-full mt-2"}
        >
          Далее
        </button>
        <GoBackBtn handleClick={backSlide} />
        {errorMsg && (
          <ErrorMsg title={errorMsg} className="-bottom-14 text-center" />
        )}
      </div>
      <Modal open={open} setOpen={setOpen} title={title} />
    </>
  )
}
