import { Close } from "@mui/icons-material"
import { Modal } from "./Modal"

export const DeleteModal = ({ active, setActive, onSubmit, text, id, loading }) => {


  function remove() {
    onSubmit(id)
  }

  return (
    <>
      <Modal active={active} setActive={setActive} className="max-w-sm">
        <div className="text-end">
          <button onClick={() => setActive(false)} className="text-gray hover:text-black h-fit">
            <Close />
          </button>
        </div>
        <p className="text-darkGray text-center max-w-[250px] mx-[auto] my-5">{text}</p>
        <div className="flex">
          <button className="btnOutlined mr-5 flex-1" onClick={() => setActive(false)}>Отмена</button>
          <button className={loading ? "opacity-50 btnFilled flex-1" :"opacity-100 btnFilled flex-1"} disabled={loading} onClick={remove}>Удалить</button>
        </div>
      </Modal>
    </>
  )
}