import { types } from "mobx-state-tree";
import { BasePageStore } from "./BasePageStore";

export const CustomersPageStore = types.compose(
    BasePageStore, types.model(
        'CustomersPageStore', {
        data: types.string
    })
)
.views(self => ({
    get query() {
        return {
            page: self.currentPage,
            per_page: self.rowsPerPage,
            search: self.searchString
        }
    }
}))