import {createSlice, createAsyncThunk} from "@reduxjs/toolkit"
import { getApiConfig } from "shared/api"
import {currentUrl} from '../../url.js'

const {accessToken} = getApiConfig()

type City = {
  id: number;
  country_id: number;
  name: string;
}

type CitiesState = {
  cities: City[];
  citiesLoading: boolean;
  citiesError: boolean;
}

export const fetchCities = createAsyncThunk<City[], undefined, {rejectValue: boolean}> (
  'cities/fetchCities',
  async function(_, {rejectWithValue}) {
    if(accessToken) {
      const res = await fetch(`${currentUrl}/master/cities`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })
      if(!res) {
        rejectWithValue(true)
      }
      const data = await res.json()

      const processedCities:City[] = data.map((item): City => ({
        id: item.id,
        country_id: item.country_id,
        name: item.name
      }))

      return processedCities
    }
  })

const initialState: CitiesState = {
  cities: [],
  citiesError: false,
  citiesLoading: true
}

const citiesSlice = createSlice({
  name: 'citiesSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCities.pending, (state) => {
        state.citiesLoading = true
        state.citiesError = false
      })
      .addCase(fetchCities.fulfilled, (state, action) => {
        state.cities = action.payload
        state.citiesLoading = false
        state.citiesError = false
      })
      .addCase(fetchCities.rejected, (state) => {
        state.citiesError = true
      })
  }
})

export default citiesSlice.reducer
