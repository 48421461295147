import React, { useState } from "react"
import { EditBtn } from "../../../shared/ui/button/EditBtn"
import { DeleteBtn } from "../../../shared/ui/button/DeleteBtn"
import { useNavigate } from "react-router-dom"
import { RemoveAnalyze } from "../../../pages/laboratory/analyzes/RemoveAnalyze"
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

const formatRightDate = (date) => {

  const newDate = new Date(date)
  const year = newDate.getFullYear()
  const month = (newDate.getMonth() + 1).toString().padStart(2, "0") // +1, так как месяцы начинаются с 0
  const day = newDate.getDate().toString().padStart(2, "0")

  return `${day}-${month}-${year}`
}
export const AnalyzesRows = ({ analyze }) => {
  const [activeRemove, setActiveRemove] = useState(false);
  const navigate = useNavigate()
  const [hovered, setHovered] = useState(false)



  // const formatter = new Intl.DateTimeFormat("ru", {
  //   weekday: "short",
  //   day: "numeric",
  //   month: "short",
  //   hour: "numeric",
  //   minute: "numeric",
  // });


  function edit() {
    navigate(`/analyzes/${analyze?.id}`)
  }

  function remove() {
    setActiveRemove(true);
  }


  return (
    <>
      <>
        <p>{analyze?.id}</p>
        <div className='relative' onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
          <p className='cursor-default' >{analyze?.surname} {analyze?.name}</p>
          {hovered && <p className='absolute p-1 border border-lightGray text-center rounded-[8px] bg-white z-10'>{analyze?.surname} {analyze?.name} {analyze?.patronymic}</p>}
        </div>
        <p>{formatRightDate(analyze?.visit)}</p>
        <p>{analyze?.phone} </p>
        <p>{analyze?.total_sum}</p>
        <span className={`rounded-[12px] text-center 
          ${analyze?.status === 'Не готов' ? "text-[#991B1B] bg-[#FEE2E2]" : "bg-[#D1FAE5] text-[#065F46]"}`}
        >{analyze?.status}</span>
        <span className={`rounded-[12px] text-center 
          ${analyze?.total_sum === analyze?.balance ? "text-[#991B1B] bg-[#FEE2E2]" : analyze?.balance === '0.00' ? 'bg-[#D1FAE5] text-[#065F46]' : "bg-[#FEF3C7] text-[#92400E]"}`}
        >{analyze?.total_sum === analyze?.balance ? 'Не оплачен' : analyze?.balance === '0.00' ? 'Оплачен' : 'Частично оплачен'}</span>
        <div className='pl-2'>
          {analyze?.sms === 1 && <CheckCircleOutlineOutlinedIcon style={{ color: "#065F46" }} />}
        </div>
        <div
          onClick={(e) => e.stopPropagation()}
          className="tableBtns h-full flex items-center w-fit justify-self-end"
        >
          <EditBtn onClick={edit} />
          {analyze?.balance === '0.00' && analyze?.status === 'Готов' ? null : <DeleteBtn onClick={remove}/>}
        </div>
      </>



      {activeRemove && (
        <RemoveAnalyze
          analyze={analyze}
          active={activeRemove}
          setActive={setActiveRemove}
        />
      )}
    </>
  )
}

