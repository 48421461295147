import { destroy, flow, getParent, types } from "mobx-state-tree";
import { ServiceModel } from "./ServiceModel";
import { CategoriesService } from "resources";
import { toast } from "react-hot-toast";

export const CategoryModel = types.model('CategoryModel', {
    id: types.identifierNumber,
    name: types.string,
    services: types.maybe(types.array(types.reference(ServiceModel))),
    categoryId: types.maybeNull(types.number),
})
    .actions((self) => ({
        update: flow(function* (name) {
            try {
                yield CategoriesService.updateCategory(self.id, { category_id: self.categoryId, name })
                self.name = name
                toast.success('Категория успешно удалена!', { position: 'bottom-right' })
            } catch (error) {
                console.error(error);
                toast.error('Не удалось удалить. Попробуйте позже!', { position: 'bottom-right' })
            }
        }),
        delete: flow(function* () {
            try {
                yield CategoriesService.deleteCategory(self.id)
                getParent(self, 2).reload()
                toast.success('Категория успешно удалена!', { position: 'bottom-right' })
            } catch (error) {
                console.error(error);
                toast.error('Не удалось удалить. Попробуйте позже!', { position: 'bottom-right' })
            }
        }),
    }))