import {
  RecordVoiceOverOutlined,
  AccessTimeOutlined,
  WindowOutlined,
  HomeRepairServiceOutlined,
  StarBorderPurple500Outlined,
  ContentPasteOutlined,
  SettingsOutlined,
  AccountBalance,
  PeopleOutlineOutlined,
  CalendarTodayOutlined,
  PersonOutlineOutlined,
  HeadsetMicOutlined,
  NotificationsNoneOutlined,
} from "@mui/icons-material"
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined"

export function getPaths() {
  return [
    // {
    //   path: "/bookings-calendar",
    //   value: "Расписание",
    //   Icon: CalendarTodayOutlined,
    //   permission: false,
    // },
    {
      path: "/bookings",
      value: "Записи",
      Icon: ContentPasteOutlined,
      permission: null,
    },
    // {
    //   path: "/notifications",
    //   value: "Уведомления",
    //   Icon: NotificationsNoneOutlined,
    //   permission: false,
    // },
    {
      path: "/customers",
      value: "Клиенты",
      Icon: RecordVoiceOverOutlined,
      permission: null,
    },
    {
      path: "/masters",
      value: "Сотрудники",
      Icon: PeopleOutlineOutlined,
      permission: null,
    },
    {
      path: "/categories",
      value: "Категории услуг",
      Icon: WindowOutlined,
      permission: null,
    },
    {
      path: "/services",
      value: "Услуги",
      Icon: HomeRepairServiceOutlined,
      permission: null,
    },
    {
      path: "/feedback",
      value: "Обратная связь",
      Icon: HeadsetMicOutlined,
      permission: null,
    },
    {
      path: "/settings",
      value: "Настройки",
      Icon: SettingsOutlined,
      permission: null,
    },
    // {
    //   path: "/profile",
    //   value: "Профиль",
    //   Icon: PersonOutlineOutlined,
    //   permission: null,
    // },
    // {
    //   path: "/templates",
    //   value: "Шаблоны",
    //   Icon: PersonOutlineOutlined,
    //   permission: null,
    // },
    // {
    //   path: "/profile",
    //   value: "Профиль",
    //   Icon: PersonOutlineOutlined,
    //   permission: null,
    // },
  ]
}
