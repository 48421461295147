import { Close } from "@mui/icons-material"
import { Modal } from "./Modal"

export function SectionsLayout({
                                 active,
                                 setActive,
                                 headerTitle,
                                 sections,
                                 onSelectSection,
                                 currentSection,
                                 className,
                                 headerClassName,
                                 children,
                               }) {
  return (
    <Modal
      active={active}
      setActive={setActive}
      className={`${className} h-full w-full flex flex-col relative`}
    >
        {/* headerMenu */}
        <div className={`h-fit ${headerClassName}`}>
          <div className="flex justify-between items-center">
            <p className="title">{headerTitle}</p>
            <button onClick={() => setActive(false)} className="h-fit">
              <Close sx={{ color: "gray.main", "&:hover": { color: "gray.dark" } }}/>
            </button>
          </div>

          <div
            className="flex justify-evenly sm:justify-start mt-5 border-b-2 border-lightGray border-solid [&>button:not(:last-child)]:mr-5">
            {sections.map((section) => (
              <button
                key={section}
                onClick={() => onSelectSection(section)}
                className={`
                outline-none hover:border-orange
                h-fit pb-1 border-b-2 border-solid border-orange translate-y-[2px]
                ${
                  currentSection === section
                    ? "text-orange border-orange"
                    : "text-blue border-transparent"
                }
              `}
              >
                {section}
              </button>
            ))}
          </div>
        </div>
        {children}
        {/* content */}

    </Modal>
  )
}