import { PhoneInput } from "../../../../shared/ui/input/PhoneInput"
import { Controller, useForm } from "react-hook-form"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { SelectContained } from "../../../../shared/ui/select/SelectContained"
import { MenuItem, Select } from "@mui/material"
import React, { memo, useEffect, useState } from "react"
import { useGetTemplatesQuery } from "../../templates/templatesApi"
import { DeleteBtn } from "../../../../shared/ui/button/DeleteBtn"
import LocalPrintshopOutlinedIcon from "@mui/icons-material/LocalPrintshopOutlined"
import { useCreateAnalyzeMutation, useGetOneAnalyzeQuery, useUpdateAnalyzeMutation } from "../analyzesApi"
import { toast } from "react-hot-toast"
import { useNavigate, useParams } from "react-router-dom"
import { TableSpinner } from "../../../../shared/ui/dataTable/spinner"
import { TemplateRow } from "../../components/TemplateRow"
import { BillModal } from "./BillModal"
import { AllTestsModal } from "./AllTestsModal"

const formatDate = (date) => {

  const newDate = new Date(date)
  const year = newDate.getFullYear()
  const month = (newDate.getMonth() + 1).toString().padStart(2, "0") // +1, так как месяцы начинаются с 0
  const day = newDate.getDate().toString().padStart(2, "0")

  return `${year}-${month}-${day}`
}



export const FormPage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { control, register, handleSubmit, formState: { errors }, watch, setValue, getValues } = useForm()
  const [templates, setTemplates] = useState([])
  const [formdata, setFormdata] = useState({})
  const [money, setMoney] = useState("")
  const [submitForBill, setSubmitForBill] = useState(!id)
  const [checkSubmittedBill, setCheckSubmittedBill] = useState(true)
  const [postResponse, setPostResponse] = useState({})
  const [openBill, setOpenBill] = useState(false)
  const [openAllTests, setOpenAllTests] = useState(false)

  const [billLoading, setBillLoading] = useState(false)
  const [addAnalyze, { isLoading: analyzeLoading }] = useCreateAnalyzeMutation()
  const [editAnalyze, { isLoading: editLoading }] = useUpdateAnalyzeMutation()
  const { data: oneAnalyze, isLoading: oneAnalyzeLoading } = useGetOneAnalyzeQuery({ id }, { skip: !id })
  const [calculateSum, setCalculateSum] = useState(id ? oneAnalyze?.report.total_sum : 0)



  useEffect(() => {
    if (templates.length !== 0) {
      setCalculateSum(templates.reduce((acc, val) => acc + Number(val.price.slice(0, -3)), 0))
    }
  }, [templates])

  const remainder = () => {
    const currentRemainder = calculateSum - money
    if (currentRemainder <= 0) {
      return "0"
    } else {
      return currentRemainder
    }
  }

  useEffect(() => {
    if (id && !oneAnalyzeLoading && oneAnalyze?.report !== null) {
      setValue("phone", ` ${oneAnalyze?.report?.phone}`)
      setValue("surname", oneAnalyze?.report?.surname)
      setValue("name", oneAnalyze?.report?.name)
      setValue("patronymic", oneAnalyze?.report?.patronymic)
      setValue("birthday", oneAnalyze?.report?.birthday.slice(0, 10))
      setValue("visit", new Date(oneAnalyze?.report?.visit.slice(0, 10)))
      setTimeout(() => {
        setValue("sex", oneAnalyze?.report?.sex)
      }, 0)
    }
  }, [id, oneAnalyzeLoading, oneAnalyze?.report, setValue])

  const onBack = () => {
    navigate("/analyzes")
  }

  const templateId = watch("services")


  const { data: templatesData, isLoading: templatesLoading } = useGetTemplatesQuery({ search: "" })

  const handleAddTemplate = () => {
    if(templates.some(obj => obj.id === templateId)) {
      return
    }
    const currentTemplate = templatesData?.data?.find(item => item.id === templateId)
    setTemplates(prev => [...prev, currentTemplate])
  }

  const handleDeleteTemplate = (id) => {
    setTemplates(templates.filter(item => item.id !== id))
  }

  const handleOpenBill = () => {
    setFormdata(getValues())
    setBillLoading(true)
    setTimeout(() => {
      setOpenBill(true)
      setBillLoading(false)
    }, 300)
  }

  const checkTemplate = templates.some(obj => obj.id === templateId)


  const onSubmit = async (data) => {
    const newPhone = data.phone.split("-").slice(1).join("")
    const template_ids = templates.map(item => item.id)
    const birthday = formatDate(data?.birthday)
    const visit = formatDate(data?.visit)
    const lastSum = calculateSum - money
    try {
      const requestData = {
        surname: data.surname || "",
        name: data.name,
        patronymic: data.patronymic || "",
        phone: newPhone,
        birthday,
        visit,
        sex: data.sex,
        template_ids,
        sum: 0,
        status: "Не готов",
        balance: lastSum,
      }


      await addAnalyze(requestData).unwrap()
      onBack()
      toast.success("Запись успешно создана", { position: "bottom-right" })

    } catch (err) {
      toast.error("Ошибка при создании записи", { position: "bottom-right" })
    }
  }
  const editSubmit = async (data) => {
    const birthday = formatDate(data?.birthday)
    const visit = formatDate(data?.visit)
    try {
      const requestData = {
        surname: data.surname || "",
        name: data.name,
        patronymic: data.patronymic || "",
        birthday,
        visit,
        sex: data.sex,
        sum: 0,
        balance: oneAnalyze?.report?.balance - money,
      }


      await editAnalyze({ id: id, ...requestData }).unwrap()
      onBack()
      toast.success("Запись успешно изменена", { position: "bottom-right" })

    } catch (err) {
      toast.error("Ошибка при изменении записи", { position: "bottom-right" })
    }
  }
  const onSubmitForCheck = async (data) => {
    const newPhone = data.phone.split("-").slice(1).join("")
    const template_ids = templates.map(item => item.id)
    const birthday = formatDate(data?.birthday)
    const visit = formatDate(data?.visit)
    const lastSum = calculateSum - money
    try {
      const requestData = {
        surname: data.surname || "",
        name: data.name,
        patronymic: data.patronymic || "",
        phone: newPhone,
        birthday,
        visit,
        sex: data.sex,
        template_ids,
        sum: 0,
        status: "Не готов",
        balance: lastSum,
      }

      const res = await addAnalyze(requestData).unwrap()
      toast.success("Запись успешно создана", { position: "bottom-right" })
      setPostResponse(res)
      setTimeout(() => {
        setOpenBill(true)
      }, 100)
      setSubmitForBill(false)
      setCheckSubmittedBill(false)
    } catch (err) {
      toast.error("Ошибка при создании записи", { position: "bottom-right" })
    }
  }

  useEffect(() => {
    if(id) {
      if(+money > +oneAnalyze?.report?.balance.slice(0, -3)) {
        setMoney(oneAnalyze?.report?.balance.slice(0, -3))
      }
    } else {
      if(+money > +calculateSum) {
        setMoney(calculateSum)
      }
    }

  }, [money, oneAnalyze?.report?.balance])

  const handleChangeBalance = (e) => {
    const value = e.target.value
    if(isNaN(value)) {
      e.preventDefault()
    } else {
      setMoney(e.target.value)
    }
  }


  return (
    <div className="sm:rounded-lg sm:bg-white px-4 pt-4 pb-10 h-[100vh] overflow-y-auto">
      {templatesLoading || oneAnalyzeLoading ?
        <div className="w-full min-h-[300px] flex justify-center items-center">
          <TableSpinner />
        </div>
        :
        <>
          <h1 className="font-medium text-[20px]">{id ? `Редактировать анализ (ID ${id})` : "Добавить анализ"}</h1>
          <form onSubmit={handleSubmit(submitForBill ? onSubmitForCheck : id ? editSubmit : onSubmit)} className="mt-4">
            <div className="flex items-center justify-center w-full gap-[20px]">
              <label className="label-base relative sm:mr-4 w-full">
                  <span>
                    Номер телефона<span className="text-red-500">*</span>
                  </span>
                <Controller
                  control={control}
                  name="phone"
                  rules={{ required: true }}
                  render={({ field }) =>
                    <PhoneInput
                      disabled={id}
                      {...field}
                    />
                  } />
                <p className={`text-red text-[12px] ${errors?.phone ? 'visible' : 'invisible'}`}>Заполните это поле</p>
              </label>

              <label className="label-base sm:mr-2 relative w-full">
                  <span>
                    Фамилия<span className="text-red-500">*</span>
                  </span>
                <div className="w-full">
                  <input
                    {...register("surname", {
                        required: "Заполните это поле",
                      },
                    )}
                    type="text"
                    placeholder="Введите имя клиента..."
                    className="inputOutlined w-full"

                  />
                  <p className={`text-red text-[12px] ${errors?.surname ? 'visible' : 'invisible'}`}>Заполните это поле</p>
                </div>

              </label>
              <label className="label-base sm:mr-2 relative w-full">
                  <span>
                    Имя<span className="text-red-500">*</span>
                  </span>
                <div className="w-full">
                  <input
                    {...register("name",
                      {
                        required: "Заполните это поле",
                      })}
                    type="text"
                    placeholder="Введите имя клиента..."
                    className="inputOutlined w-full"

                  />
                  <p className={`text-red text-[12px] ${errors?.name ? 'visible' : 'invisible'}`}>Заполните это поле</p>
                </div>

              </label>
              <label className="label-base sm:mr-2 relative w-full">
                  <span>
                    Отчество
                  </span>
                <div className="w-full">
                  <input
                    {...register("patronymic")}
                    type="text"
                    placeholder="Введите имя клиента..."
                    className="inputOutlined w-full"

                  />
                </div>

                <p className={`text-red text-[12px] ${errors?.patronymic ? 'visible' : 'invisible'}`}>Заполните это поле</p>
              </label>
            </div>


            <div
              className="col-span-3 flex justify-between items-start [&>label]:w-full [&>label]:flex [&>label]:flex-col mt-4 sm:mt-3">
              <label className="label-base w-full mr-5">
                <span>Дата рождения<span className="text-red-500">*</span></span>
                <input type="date" {...register("birthday", {
                  required: "Заполните это поле",
                })} className="inputOutlined" />

                <p className={`text-red text-[12px] ${errors?.birthday ? 'visible' : 'invisible'}`}>{errors?.birthday?.message}</p>
              </label>


              <label className="label-base w-full mr-5">
                <span className="">Пол<span className="text-red-500">*</span></span>
                <div className="w-full">
                  <Controller
                    name="sex"
                    control={control}
                    rules={{ required: "Выберите пол" }}
                    render={({ field }) => (

                      <Select
                        {...field}
                        defaultValue={oneAnalyze?.report?.sex}
                        size="small"
                        color="secondary"
                        sx={{
                          fontSize: "16px",
                          backgroundColor: "white",
                          width: "100%",
                          height: "48px",
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "1px solid #EDEDED !important",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#A4AFBD !important",
                          },
                        }}
                        fullWidth
                        variant="outlined"
                      >
                        <MenuItem value={"Мужской"}>
                          Мужской
                        </MenuItem>
                        <MenuItem value={"Женский"}>
                          Женский
                        </MenuItem>
                      </Select>

                    )} />
                </div>
                {errors?.sex && <p className="text-red">{errors?.sex?.message}</p>}
              </label>

              <label className="label-base w-full mr-2">
                <span>Дата визита<span className="text-red-500">*</span></span>
                <Controller
                  control={control}
                  defaultValue={new Date()}
                  rules={{ required: "Заполните это поле" }}
                  name="visit"
                  render={({ field }) =>
                    <DatePicker
                      showIcon
                      minDate={new Date()}
                      selected={field.value}
                      onChange={(date) => field.onChange(date)}
                      placeholderText="мм.дд.гггг"
                      dateFormat="dd-MM-yyyy"
                      style={{ paddingLeft: "20px", width: "100%" }}
                      className="inputOutlined mt-1 w-full text-left !pl-10"
                      icon={
                        <div className="mt-[9px] mr-4">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_8637_17044)">
                              <path
                                d="M17 3H21C21.2652 3 21.5196 3.10536 21.7071 3.29289C21.8946 3.48043 22 3.73478 22 4V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196 20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196 2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3 3 3H7V1H9V3H15V1H17V3ZM15 5H9V7H7V5H4V9H20V5H17V7H15V5ZM20 11H4V19H20V11Z"
                                fill="#A4AFBD" />
                            </g>
                            <defs>
                              <clipPath id="clip0_8637_17044">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </div>

                      }
                    />}
                />
                {errors?.visit && <p className="text-red">{errors?.visit?.message}</p>}
              </label>
            </div>


            {!id &&
              <div>
                <div
                  className="col-span-3 flex justify-between items-end [&>label]:w-full [&>label]:flex [&>label]:flex-col mt-4 sm:mt-8">
                  <label className="label-base w-full mr-5">
                    <span className="">Шаблоны</span>
                    <div className="w-full">
                      <Controller
                        name="services"
                        control={control}
                        render={({ field }) => (
                          <SelectContained
                            {...field}
                            className="mt-1"
                          >
                            {templatesData?.data?.map(item => <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>)}

                          </SelectContained>
                        )} />
                    </div>
                  </label>
                  <button
                    disabled={templateId === undefined || checkTemplate}
                    onClick={handleAddTemplate}
                    type="button"
                    className={templateId === undefined || checkTemplate ?
                      "opacity-50 btnFilled cursor-pointer h-full" : "opacity-100 btnFilled cursor-pointer h-full"}
                  >Добавить
                  </button>
                </div>
              </div>}

            <div className="flex items-center justify-center w-full mt-10 px-7 border-b pb-4 border-[#EDEDED]">
              <p className="whitespace-nowrap font-medium text-[#A4AFBD] text-[12px] mr-8">№</p>
              <p className="w-full whitespace-nowrap font-medium text-[#A4AFBD] text-[12px]">Услуга</p>
              <p className="w-full whitespace-nowrap font-medium text-[#A4AFBD] text-[12px]">Цена</p>
              <p className="w-full whitespace-nowrap font-medium text-[#A4AFBD] text-[12px]">Анализ</p>
              <p className="whitespace-nowrap font-medium text-[#A4AFBD] text-[12px]"></p>
            </div>
            <div className="max-h-[200px] overflow-y-auto overflow-x-hidden">
              {id ? oneAnalyze?.templates.map(item =>
                <TemplateRow key={item.id} {...item} data={oneAnalyze}/>,
              ) : templates.map(item =>
                <div key={item.id}
                     className="flex items-center justify-start w-full py-4 border-b border-[#EDEDED] px-7">
                  <p className="whitespace-nowrap font-medium text-[#1E3148] text-[14px] mr-8">{item.id}</p>
                  <p
                    className="w-full whitespace-nowrap font-medium text-[#1E3148] text-[14px]">{item.name} {item.tests} показателей</p>
                  <p className="w-full whitespace-nowrap font-medium text-[#1E3148] text-[14px] ml-6">{item.price}</p>

                  <div className="w-full whitespace-nowrap font-medium text-[12px] ml-6">
                    <span className="py-[2px] px-[10px] rounded-[14px] bg-[#FEE2E2] text-[#991B1B]">Не готов</span>
                    {/*<span className="py-[2px] px-[10px] rounded-[12px] bg-[#D1FAE5] text-[#065F46]">Готов</span>*/}
                  </div>
                  <p className="whitespace-nowrap font-medium text-[#A4AFBD] text-[12px]">
                    <div className="flex items-center justify-end">
                      <DeleteBtn
                        onClick={(e) => handleDeleteTemplate(item.id)}
                      />
                    </div>
                  </p>
                </div>)}
            </div>
            <div className="flex items-center justify-center w-full mt-10 px-6">
              <p className="w-full whitespace-nowrap font-medium text-[16px] mr-8">Итог:</p>
              <p
                className="w-full whitespace-nowrap font-medium text-[16px]">{id ? oneAnalyze?.report?.total_sum : `${calculateSum}.00`}</p>
              <p className="w-full whitespace-nowrap font-medium text-[#A4AFBD] text-[12px]"></p>
            </div>

            <div className="flex items-center justify-center w-full mt-10 px-6">
              <p className="w-full whitespace-nowrap font-medium text-[16px] mr-10">Сумма к оплате:</p>
              <input
                disabled={oneAnalyze?.report?.balance === "0.00"}
                value={money}
                min={0}
                pattern={'[0-9]*'}
                placeholder={"Введите сумму"}
                onChange={(e) => handleChangeBalance(e)}
                className="outline-none w-[160px] text-center h-[40px] border border-[#EDEDED] rounded-[10px] px-2 py-1 focus:border-[#EDEDED] active:border-[#EDEDED]" />
              <p className="w-full whitespace-nowrap font-medium text-[#A4AFBD] text-[12px]"></p>
              <p className="w-full whitespace-nowrap font-medium text-[#A4AFBD] text-[12px]"></p>
            </div>
            <div className="flex items-center justify-center w-full mt-10 pl-6">
              <p className="w-full whitespace-nowrap font-medium text-[16px] mr-[110px]">Остаток:</p>
              <p
                className="w-full whitespace-nowrap font-medium text-[16px]">{id ? oneAnalyze?.report?.balance - money : remainder() ? `${remainder()}.00 TJS` : ""}</p>
              <p className="w-full whitespace-nowrap font-medium text-[16px]"></p>
              <div className='w-[300px]'>
                <button type={submitForBill ? 'submit' : 'button'}
                        name={'bill'}
                        disabled={billLoading || analyzeLoading}
                        onClick={submitForBill ? () => {} : handleOpenBill}
                        className={billLoading || analyzeLoading ? "opacity-50  py-3 px-4 border border-[#EE6F42] w-[210px] text-[#EE6F42] flex items-center justify-between rounded-[8px] hover:bg-[#EE6F42] hover:text-white transition-all" : "py-3 w-[210px] px-4 border border-[#EE6F42] text-[#EE6F42] flex items-center justify-between rounded-[8px] hover:bg-[#EE6F42] hover:text-white transition-all"}>
                  <LocalPrintshopOutlinedIcon />
                  <span className="text-[16px] font-medium ml-2 w-full">Напечатать чек</span>
                </button>
              </div>
            </div>

            <div className="flex items-center justify-between w-full mt-8">
              <div className='flex items-center justify-center gap-4'>
                <button
                  disabled={!id}
                  onClick={() => setOpenAllTests(true)}
                  type={"button"}
                  className={`flex items-center justify-between px-4 py-3 gap-2 text-white bg-[#1E314833] rounded-[8px] transition-all ${id ? 'hover:bg-[#A4AFBD]' : ''}  `}>
                  <LocalPrintshopOutlinedIcon />
                  <span>Напечатать ответ</span>
                </button>


              </div>

              <div className="flex items-center justify-center gap-5">
                <button onClick={onBack} type={"button"}
                        className="py-3 px-6 border rounded-[8px] border-[#737C86] text-[#737C86] hover:bg-[#1E314833] transition-colors">
                  Отмена
                </button>
                <button
                  name={'save'}
                  type={checkSubmittedBill ? 'submit' : 'button'}
                  onClick={checkSubmittedBill ? () => {}  : () => navigate('/analyzes')}
                  disabled={editLoading || analyzeLoading}
                  className={editLoading || analyzeLoading ? "opacity-50 btnFilled px-4" : "btnFilled px-4"}>
                  Сохранить
                </button>
              </div>
            </div>


          </form>
        </>}
      <BillModal data={oneAnalyze} calculatedSum={id ? `${oneAnalyze?.report?.total_sum} TJS` : `${calculateSum}.00 TJS`}
                 balance={id ? `${oneAnalyze?.report?.balance - money}.00 TJS` : remainder() ? `${remainder()}.00 TJS` : ""}
                 money={money} formdata={formdata} templates={templates} openBill={openBill}
                 setOpenBill={setOpenBill} postResponse={postResponse}/>
      <AllTestsModal open={openAllTests} setOpen={setOpenAllTests} data={oneAnalyze}/>
    </div>
  )
}

