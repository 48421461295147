import { ExpandMoreOutlined } from '@mui/icons-material'
import React from 'react'
import { Select } from '@mui/material'

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 170,
      width: 250,
    },
  },
};

export const SelectContained = React.forwardRef(({ name, value, onChange, children, className, disabled, placeholder, defaultValue, field, time }, ref) => {

  return (
    <>
    <div className={`flex items-center h-full ${className}`} >
      <Select
        {...field}
        ref={ref}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        disabled={disabled}
        size='small'
        color='secondary'
        MenuProps={MenuProps}
        IconComponent={ExpandMoreOutlined}
        sx={{
          fontSize: '16px',
          backgroundColor: 'white',
          width: '100%',
          paddingLeft: time ? '30px' : '',
          height: '48px',
          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid #EDEDED !important'
          },
          '&:hover .MuiOutlinedInput-notchedOutline':{
            borderColor: '#A4AFBD !important'
          },
        }}
        name={name}
        fullWidth
        variant='outlined'
      >
        {children}
      </Select>
    </div>
    </>
  )
})
