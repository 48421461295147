import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { baseQueryWithReauth, getApiConfig } from "shared/api"

const { BASE_URL, prepareHeaders, accessToken } = getApiConfig()

function photoPrepareHeaders(headers) {
  headers.set("Authorization", `Bearer ${accessToken}`)
  headers.set("Accept", "application/json")
  return headers
}


export const settingsApi = createApi({
  reducerPath: "settingsApi",
  tagTypes: "Settings",
  baseQuery: baseQueryWithReauth({baseUrl: BASE_URL, prepareHeaders: photoPrepareHeaders}),
  endpoints: build => ({
    getSpheres: build.query({
      query: () => `master/categories`
    }),
    getSalonSettings: build.query({
      query: (params) => `master/salons/${params.salon_id}`
    }),
    editSettings: build.mutation({
      query: ({id, ...body }) => ({
        url: `master/salons/${id}`,
        method: 'PUT',
        body
      })
    }),
    postSalonAvatar: build.mutation({
      query: ({id, ...body}) => ({
        url: `master/salons/${id}/update-image`,
        method: 'POST',
        body: body.formData
      })
    }),
    getCities: build.query({
      query: () => `master/cities`
    })
  })
})

export const {useGetSpheresQuery, useGetSalonSettingsQuery, useEditSettingsMutation, usePostSalonAvatarMutation, useGetCitiesQuery} = settingsApi
