import { createAsyncThunk } from "@reduxjs/toolkit"
import dayjs from "dayjs"

import { createApi } from "@reduxjs/toolkit/dist/query/react"

import { baseQueryWithReauth, getApiConfig } from "shared/api"

const { BASE_URL, prepareHeaders } = getApiConfig()
const BOOKINGS_PATH = "master/bookings"

function transformGetBookingsResponse({ data }) {
  return data.map(
    ({
      id,
      name,
      master_name,
      master_surname,
      surname,
      master_id,
      duration,
      master,
      client_phone,
      status_id,
      service_id,
      booking_id,
      price,
      start,
      end,
      service,
    }) => ({
      id,
      master_id,
      status_id,
      service_id,
      name,
      duration,
      price,
      booking_id,
      phone: client_phone,
      surname,
      startDate: dayjs(`${start}`).toDate(),
      endDate: dayjs(new Date(`${end}`)).toDate(),
      services: [service],
      color:
        status_id === 1
          ? "#EDF1F7"
          : status_id === 2
            ? "#FFF5DD"
            : status_id === 3
              ? "#D5F5CC"
              : "",

      master_name,
      master_surname,
    })
  )
}

export const bookingsApi = createApi({
  reducerPath: "bookingsApi",
  tagTypes: ["Bookings"],
  baseQuery: baseQueryWithReauth({ baseUrl: BASE_URL, prepareHeaders }),
  endpoints: (builder) => ({
    //get all
    getBookings: builder.query({
      query: () => `/${BOOKINGS_PATH}`,
      providesTags: ({ data }) => {
        if (data) {
          return [
            ...data.map(({ id }) => ({ type: "Bookings", id })),
            { type: "Bookings", id: "LIST" },
          ]
        }
        return [{ type: "Bookings", id: "LIST" }]
      },
      transformResponse: transformGetBookingsResponse,
    }),
    getAllBookings: builder.query({
      query: (bookingFormat = '') => `/${BOOKINGS_PATH}?year=${bookingFormat?.year}&month=${bookingFormat?.month}${bookingFormat?.day && `day=${bookingFormat?.day}`}`,
      providesTags: ({ data }) => {
        if (data) {
          return [
            ...data.map(({ id }) => ({ type: "Bookings", id })),
            { type: "Bookings", id: "LIST" },
          ]
        }
        return [{ type: "Bookings", id: "LIST" }]
      },
      transformResponse: transformGetBookingsResponse,
    }),
    postBooking: builder.mutation({
      query: (newBooking) => ({
        url: `master/bookings`,
        method: "POST",
        body: newBooking,
      }),
      invalidatesTags: [{ type: "Bookings", id: "LIST" }]
    }),
    updateBooking: builder.mutation({
      query: ({ id, status_id }) => ({
        url: `master/bookings/${id}`,
        method: "PATCH",
        body: { status_id: status_id },
      }),
      invalidatesTags: [{ type: "Bookings", id: "LIST" }]
    }),
    deleteBooking: builder.mutation({
      query: (id) => ({
        url: `master/bookings/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Bookings", id: "LIST" }]
    })
  }),
})

export const { useGetBookingsQuery, useGetAllBookingsQuery, usePostBookingMutation, useDeleteBookingMutation, useUpdateBookingMutation } = bookingsApi

// post new Booking
export const postBooking = createAsyncThunk(
  "bookings/post",
  async (newBooking, thunkApi) => {
    const STATUS_SUCCESS = "Запись успешно создана";
    const STATUS_FAILED = "Что-то пошло не так...";
    try {
      const res = await bookingsApi.postBooking(
        // {
        //   date: "2023-02-21",
        //   time: "10:00",
        //   user_id: 1,
        //   service_id: 1,
        //   master_id: 1,
        // }
        newBooking
      );
      if (res.statusText) return STATUS_SUCCESS;
    } catch (error) {
      // если надо будет показывать получаемую ошибку
      const errorMessage = error.response.data.message;
      // сейчас сообщение об ошибке для всех случаев одно
      return thunkApi.rejectWithValue(STATUS_FAILED);
    }
  }
);

export const getBooking = createAsyncThunk(
  "bookings/get",
  async (newBooking, thunkApi) => {
    try {
      const res = await bookingsApi.getAllBookings(
        newBooking
      );
      return res;
    } catch (error) {
      const errorMessage = error.response.data.message;
      return thunkApi.rejectWithValue(errorMessage);
    }
  }
);

