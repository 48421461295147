import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { getApiConfig } from "shared/api"
import toast from "react-hot-toast"
import {currentUrl} from '../../url.js'

const { accessToken } = getApiConfig()

interface Status {
  id: number;
  name: string;
}

type StatusesState = {
  statuses: Status[];
  loading: boolean;
  error: boolean;
}

export const fetchStatuses = createAsyncThunk<Status[], undefined, { rejectValue: boolean }>(
  "statuses/fetchStatuses",
  async function(_, { rejectWithValue }) {
    try {
      if(accessToken) {
        const res = await fetch(`${currentUrl}/master/status`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        const someData = await res.json()
        return someData?.data
      }
    } catch (err) {
      toast.error("Ошибка при загрузке данных, попробуйте позже", { position: "bottom-right" })
    }
  },
)

const initialState: StatusesState = {
  statuses: [],
  loading: false,
  error: false,
}

const statusesSlice = createSlice({
  name: "statuses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStatuses.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(fetchStatuses.fulfilled, (state, action) => {
        state.statuses = action.payload
        state.loading = false
      })
      .addCase(fetchStatuses.rejected, (state) => {
        state.loading = false
        state.error = true
      })
  },
})

export default statusesSlice.reducer
