import {Skeleton} from "@mui/material";

export function AuthSkeleton() {
  return (
    <>
      <div className="w-full h-screen flex">
        <div className="w-[40vh] h-full">
          <Skeleton variant="rounded" width='100%' height='100%' animation='wave'/>
        </div>

        <div className="w-full h-full flex items-center justify-center">
          <div className="w-full max-w-[420px]">
            <Skeleton variant="rounded" width='100%' height='100px'/>
            <br/>
            <Skeleton variant="rounded" width='100%' height='48px'/>
            <br/>
            <Skeleton variant="rounded" width='100%' height='48px'/>
          </div>
        </div>
      </div>
    </>
  )
}