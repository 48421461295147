export function Logo() {
  return (
    <svg
      width="62"
      height="46"
      viewBox="0 0 62 46"
      fill="none"
      xmlns="//www.w3.org/2000/svg"
    >
      <circle
        cx="49.5264"
        cy="5.49868"
        r="4.6431"
        transform="rotate(-90 49.5264 5.49868)"
        fill="#EE6F42"
      />
      <circle
        cx="6.19071"
        cy="41.0959"
        r="4.6431"
        transform="rotate(-90 6.19071 41.0959)"
        fill="#EE6F42"
      />
      <path
        d="M40.8812 25.3372C39.5023 25.3372 38.8118 23.6701 39.7868 22.6951L48.1502 14.3316C48.7546 13.7272 49.7346 13.7272 50.339 14.3316L58.7024 22.6951C59.6774 23.6701 58.9869 25.3372 57.6081 25.3372L40.8812 25.3372Z"
        fill="#1E3148"
      />
      <path
        d="M38.6924 45.7389C48.8456 45.7389 55.7171 36.6214 55.7171 27.1665V25.6188C55.7171 23.9093 54.3313 22.5234 52.6217 22.5234H46.4309C44.7214 22.5234 43.3355 23.9093 43.3355 25.6188V27.1665C43.3355 31.388 40.5061 33.3573 38.6924 33.3573C36.8788 33.3573 34.0493 31.388 34.0493 27.1665V21.7496H21.6677V27.1665C21.6677 36.6214 28.5393 45.7389 38.6924 45.7389Z"
        fill="#1E3148"
      />
      <path
        d="M17.0247 0.855591C6.87156 0.855591 0 9.9731 0 19.428L0 30.2619C0 31.9714 1.38586 33.3573 3.0954 33.3573H9.2862C10.9957 33.3573 12.3816 31.9714 12.3816 30.2619V19.428C12.3816 15.2065 15.211 13.2372 17.0247 13.2372C18.8384 13.2372 21.6678 15.2065 21.6678 19.428V24.0711H34.0494V19.428C34.0494 9.9731 27.1778 0.855591 17.0247 0.855591Z"
        fill="#1E3148"
      />
    </svg>
  );
}

