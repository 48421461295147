export const Footer = () => {
    return (
        <div className="flex md+:flex-row flex-col-reverse container items-center justify-center absolute bottom-6 content-end flex-1">
            <div className="text-darkGray absolute left-2 md+:flex md+:gap-x-3 gap-x-10 hidden">&copy; {new Date().getFullYear()} Navbat</div>
            <div className="pb-2 md+:pb-0"><a href="//navbat.tj"><img src='/assets/logo/logo_black.svg' alt='navbat' /></a></div>
            <div className="md+:flex md+:gap-x-3 gap-x-10 hidden items-center absolute px-10 right-2">
                <a
                    href="//navbat.tj/"
                    className="
                   text-darkGray
                  hover:underline cursor-pointer h-fit w-fit
                "
                >
                    Главная
                </a>
                <a
                    href="//navbat.tj/#features"
                    className="
                   text-darkGray
                  hover:underline cursor-pointer h-fit w-fit
                "
                >
                    О продукте
                </a>
                <a
                    href="//navbat.tj/#pricing"
                    className="
                   text-darkGray
                  hover:underline cursor-pointer h-fit w-fit
                "
                >
                    Цены
                </a>
            </div>
        </div>
    )
}
