import { Login } from "./login/index"

export const AuthPage = () => {

  return (
    <>
      <div className="w-full h-[100vh] flex">
        <Login />
      </div>
    </>
  )
}