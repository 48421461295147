import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Pagination } from "swiper"
import "swiper/css"
import "swiper/css/bundle"
import "swiper/css/navigation"
import "swiper/css/pagination"

import { CodeConfirmation } from "widgets/auth/CodeConfirmation"
import { EnterPhone } from "pages/auth/changePass/EnterPhone"
import { CreatePassword } from "widgets/auth/CreatePassword"
import { StepsBar } from "./StepsBar"
import "../style.css"
import { useConfirmCodeMutation, useSendCodeMutation, useUpdatePasswordMutation } from "../slice"
import { Footer } from "shared/ui/footer"
import { toast } from "react-hot-toast"

export default function ChangePass() {
  const navigate = useNavigate()
  const [swiper, setSwiper] = useState()
  const [pageNum, setPageNum] = useState(1)
  const [phoneError, setPhoneError] = useState(false)

  function backSlide() {
    swiper.slidePrev()
    setPageNum(pageNum - 1)
  }

  function nextSlide() {
    swiper.slideNext()
    setPageNum(pageNum + 1)
  }

  const [sendCodeQuery] = useSendCodeMutation()
  const [confirmCodeQuery] = useConfirmCodeMutation()
  const [updatePasswordQuery] = useUpdatePasswordMutation()

  const [changePassData, setChangePassData] = useState({
    phone: "",
    newPassword: "",
    code: null,

    codeError: null,
    phoneError: null,
    updateError: null,
  })

  async function sendCode(phone) {
    setPhoneError(false)
    setChangePassData({ ...changePassData, phoneError: null })
    try {
      await sendCodeQuery({ phone, action: "forgot_password" }).unwrap()
      nextSlide()
      setChangePassData({ ...changePassData, phone, phoneError: null })
    } catch (err) {
      console.log(err)
      if(err.status === 422 || err.status === 404) {
        toast.error('Такого пользователя не существует', {position: 'bottom-right'})
        setPhoneError(true)
      }
      setChangePassData({ ...changePassData, phoneError: err?.data?.message })
    }
  }

  async function confirmCode(code) {
    setChangePassData({ ...changePassData, codeError: null })
    try {
      await confirmCodeQuery({ phone: changePassData.phone, code }).unwrap()
      setChangePassData({ ...changePassData, code })
      nextSlide()
    } catch ({ data: { message } }) {
      setChangePassData({ ...changePassData, codeError: message ?? "Попробуйте ещё раз" })
    }
  }

  async function resent() {
    setChangePassData({ ...changePassData, codeError: null })
    try {
      await sendCodeQuery({ phone: changePassData.phone, action: "forgot_password" }).unwrap()
    } catch ({ data: { message } }) {
      setChangePassData({ ...changePassData, phoneError: message })
    }
  }

  async function updatePassword(newPassword) {
    try {
      await updatePasswordQuery({ phone: changePassData.phone, code: changePassData.code, newPassword }).unwrap()
      goToLogin()
      toast.success('Ваш пароль успешно изменен', {position: 'bottom-right'})
    } catch (error) {
      setChangePassData({ ...changePassData, updateError: "Что-то пошло не так, попробуйте ещё раз" })
    }
  }

  const [mounted, setMounted] = useState(false)

  function goToLogin() {
    setMounted(false)
    setTimeout(() => {
      navigate("/login")
    }, 500)
  }

  useEffect(() => {
    setMounted(true)
  }, [])

  return (
    <div className="flex auth changePassSliderWrap">
      {/* slider progress bar */}
      <StepsBar mounted={mounted} pageNum={pageNum} />

      {/* slider */}
      <div
        className={`${
          mounted ? "opacity-100" : "opacity-0"
        } transition-opacity duration-500 flex flex-1 justify-center`}
      >
        <Swiper
          className="changePassSlider"
          onSwiper={setSwiper}
          modules={[Pagination, Navigation]}
          direction={"vertical"}
          allowTouchMove={false}
        >
          <SwiperSlide>
            <EnterPhone
              phoneError={phoneError}
              backSlide={goToLogin}
              submit={sendCode}
              errorMsg={changePassData.phoneError}
            />
          </SwiperSlide>
          <SwiperSlide className="slider-item">
            <CodeConfirmation
              submit={confirmCode}
              backSlide={backSlide}
              errorMsg={changePassData.codeError}
              resent={resent}
            />
          </SwiperSlide>
          <SwiperSlide>
            <CreatePassword submit={updatePassword} backSlide={backSlide} errorMsg={changePassData.updateError} />
          </SwiperSlide>
        </Swiper>
        <Footer />
      </div>
    </div>
  )
}
