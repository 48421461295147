export const ServiceTitles = ({ titles, children, className }) => {
  const length = titles?.length
  return (
    <>
      {/* titles */}
      <div
        className={`${className} grid ${
          "grid-cols-" + length
        } py-4 text-lg [&>p]:pr-2`}
      >
        {titles?.map((item) => (
          <p
            key={item}
            className='last:text-end last:pr-4 text-[16px] text-[#A4AFBD] whitespace-nowrap overflow-hidden text-ellipsis w-full'
          >
            {item}
          </p>
        ))}
      </div>

      {/* body */}
      {children}
    </>
  )
}
