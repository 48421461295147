export const DataTable = ({ titles, children, className }) => {
  const length = titles.length
  return (
    <>
      {/* titles */}
      <div
        className={`${className} grid ${"grid-cols-" + length} py-4 text-lg [&>p]:pr-2`}
      >
        {titles.map((item) => (
          <p
            key={item}
            className='last:text-end whitespace-nowrap overflow-hidden text-ellipsis w-full'
          >
            {item}
          </p>
        ))}
      </div>

      {/* body */}
      {children}
    </>
  )
}
