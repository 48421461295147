import Modal from "./Modal"
import React from "react"

export const AfterFirstStepModal = ({open, setOpen}) => {
  return (
    <Modal setOpen={setOpen} open={open}>
      <div className="w-full bg-white p-4">
        <article className='w-full flex flex-col items-center justify-center'>
          <h1 className="font-medium text-center w-full text-[20px]">Отлично!</h1>
          <p className='mt-4'>А теперь переходите в раздел <b>Категории услуг</b></p>
        </article>
      </div>
    </Modal>
  )
}

